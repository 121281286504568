/*
|--------------------------------------------------------------------------
| Exportable Array Utility functions
|--------------------------------------------------------------------------
| Here is where you can create functions that you think can be used often.
| To reduce code redundancy. Also, please provide comments that describe 
| the functions. See jsdoc.app for documentation.
|
*/
import axios from "@/AxiosIndex"

/**
 * Removes duplicate value from array
 * ```js
 * //Example
 * const duplicates = [🍎, 🍎, 🍌, 🍍]
 * removeDuplicae(duplicates) //[🍎, 🍌, 🍍]
 * ```
 * @param {array} array Array to be de-duplicated
 * @returns {array}
 */
export const removeDuplicate = (array) => [...new Set(array)]

/**
 * Invert the key - value pair of object
 * ```js
 * const object = {apple: 🍎, banana: 🍌}
 * invertObject() //{🍎: apple, 🍌: banana}
 * ```
 * @param {object} obj
 * @returns {object} Inverted Object
 */
 export const invertObject = obj => {
    if(obj?.constructor?.name !== 'Object')
        throw new Error('Given value is not an object')

    return Object.fromEntries(Object.entries(obj).map(array => array.reverse()))
}

export const addOrRemove = (arr, val) => {
    var index = arr.indexOf(val);

    if (index === -1) {
        arr.push(val);
    } else {
        arr.splice(index, 1);
    }
}

export const addOrRemove_object = (arr, obj, id_key) => {
    var index = arr.findIndex(item => item[id_key] === obj[id_key])

    if (index === -1) arr.push(obj)
    else arr.splice(index, 1)

    return arr
}

export const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0
}
/**
 * 
 * @param { Array } arr 
 * @param { String } keyword | array key to be sorted
 * @param { String } by | sort order
 * @returns { Array } Sorted Array
 */
export const arraySort_byStringKeyword = (arr, keyword, by = 'asc') => {
    let sorted_arr = arr
    if(by == 'asc') {
        sorted_arr = arr.sort((a, b) => a?.[keyword].localeCompare(b?.[keyword]))
    } else {
        sorted_arr = arr.sort((a, b) => b?.[keyword].localeCompare(a?.[keyword]))
    }
    return sorted_arr
}

/**
 * 
 * @param { Array } arr 
 * @param { String } keyword | array key to be sorted
 * @param { String } by | sort order
 * @returns { Array } Sorted Array
 */
export const arraySort_byNumberKeyword = (arr, keyword, by = 'asc') => {
    let sorted_arr = arr
    if(by == 'asc') {
        sorted_arr = arr.sort((a, b) => a?.[keyword] - b?.[keyword])
    } else {
        sorted_arr = arr.sort((a, b) => b?.[keyword] - a?.[keyword])
    }
    return sorted_arr
}

/**
 * 
 * @param { Array } arr 
 * @param { String } by | sort order
 * @returns { Array } Sorted Array
 */
export const arraySort_byNumber = (arr, by = 'asc') => {
    let sorted_arr = arr
    if(by == 'asc') {
        sorted_arr = arr.sort((a, b) => a - b)
    } else {
        sorted_arr = arr.sort((a, b) => b - a)
    }
    return sorted_arr
}

export const arraySort_byStringsAndNumbers = (a, b, order = 'asc') => {
    const collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
    const comparison = collator.compare(a, b);

    if (order === 'desc') {
        return comparison * -1;
    }

    return comparison;
}