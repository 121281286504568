<template>
    <div class="animated fadeIn faster">
        <div class="titlebar-con">
            <ar-titlebar
                :campaign="campaign_data.name"
                :from_count="from_page_count"
                :to_count="to_page_count"
                :max_count="visible_total"
                :subject="$t('Imported Data')"
                :img="campaign_data.img"
                is_listings
                a_style="border-bottom: 2px solid #dee2e6"
                :selected_count ="selected_listings.length"
                :selected       ="selected_listings.length > 0"
                :action_text    ="select_all_text"
                selected_subject="Data"
                @click          ="() => { is_select_all = true; checkAllListing(); }"
                @uns-click      ="() => { is_select_all = false; checkAllListing(); }"
                :selected_max_count="page_size"
            >
                <template v-slot:extra>
                    <div class="ml-3 d-inline-block border-left" style="border-width: 2px !important;">
                        <ar-button
                            icon="fas fa-plus"
                            :is_fab="true"
                            style="padding-top: 8px; padding-bottom:8px;"
                            class="ml-3"
                            @click="openAddModal()"
                        >
                            Import Data
                        </ar-button>
                    </div>
                </template>
                <template v-slot:search-form>
                    <div class="form-inline">
                        <div class="a-input-group">
                            <ar-textfield 
                                :placeholder        ="$t('Search Data here')" 
                                type                ="text"
                                a_class             ="mr-1"
                                a_style             ="width: 328px;"
                                icon                ="fas fa-search"
                                v-model.trim        ="filters.search.value"
                                @keyup              ="current_page = 1"
                            />
                        </div>
                    </div>
                </template>
                <template>
                    <a
                        href="javascript:void(0);"
                        class="mr-3 action-item"
                        data-toggle="modal"
                        title="Download Data"
                        @click="openModal('download-confirmation')"
                    >
                        <!-- data-target="#change-status-modal" -->
                        <!-- @click="resetCommission(); getPredefinedReasons(ListingStatus.HasPotential)" -->
                        <img src="/static/svg/Lawfirm/Download Icon.svg" alt="Download Data Checker" />
                    </a>
                </template>
            </ar-titlebar>
            <div class="p-2 pl-4">
                <div class="dropdown d-inline-block">
                    <a 
                        href="javascript:void(0);"
                        id="sortClaimtypeDropdown"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <ar-button 
                            class="position-relative font-weight-medium pl-3 a-btn-text"
                            style="font-size: 12px; text-align:center; padding-left: 12px !important; padding-right: 12px; padding-top: 3px; padding-bottom: 3px; margin-top: 2px;">
                            <img src="/static/svg/notifications/Sort_Campaign.svg" class="position-relative" style="margin-right: 2px; top: -1px" alt="icon">
                            Sort By - {{ selectedFilter.claimtype }}
                        </ar-button>
                    </a>
                    <div class="dropdown-menu" aria-labelledby="sortClaimtypeDropdown" style="min-height:0; max-height: 300px; overflow-y: auto;">
                        <a
                            class="dropdown-item"
                            href="javascript:void(0);"
                            @click="filters.claimtype.selected = 0"
                        >
                            All Claim Types
                        </a>
                        <a
                            class="dropdown-item"
                            href="javascript:void(0);"
                            @click="filters.claimtype.selected = -1"
                        >
                            No Claim Type
                        </a>
                        <a
                            v-for="(option, index) in filters.claimtype.data"
                            :key="`claim-type--${index}`" 
                            class="dropdown-item"
                            href="javascript:void(0);"
                            @click="filters.claimtype.selected = option.value"
                        >
                            {{ option.label }}
                        </a>
                    </div>
                </div>
                <div class="dropdown d-inline-block pl-3 ml-2 has-bullet-separator">
                    <a 
                        href="javascript:void(0);"
                        id="datePickerDropdown"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <ar-button 
                            class="position-relative font-weight-medium pl-3 a-btn-text"
                            style="font-size: 12px; text-align:center; padding-left: 12px !important; padding-right: 12px; padding-top: 3px; padding-bottom: 3px; margin-top: 2px;">
                            <img src="/static/svg/notifications/Sort_Date.svg" class="position-relative" style="margin-right: 2px; top: -2px" alt="icon">
                            {{ selectedDaterange }}
                        </ar-button>
                    </a>
                    <div class="dropdown-menu p-3" aria-labelledby="datePickerDropdown" style="width: 376px;">
                        <div class="d-flex justify-content-between align-items-center">
                            <div>
                                <label class="col-form-label from-label">From:</label>
                                <div class="position-relative"><input type="date" class="form-control" @blur="checkDateFilter('start')" v-model="filters.daterange.start" :max="filters.daterange.end" /></div>
                            </div>
                            <div>
                                <label class="col-form-label from-label">To:</label>
                                <div class="position-relative"><input type="date" class="form-control" @blur="checkDateFilter('end')" v-model="filters.daterange.end" :min="filters.daterange.start" :max="filters.daterange.max"/></div>
                            </div>
                        </div>
                        <div class="text-right">
                            <ar-button outlined class="apply-button" v-if="showClearSelectedDaterangeButton" @click="resetDateFilter">Clear</ar-button>
                            <ar-button class="apply-button ml-2" @click="applyFilter">Apply</ar-button>
                        </div>
                    </div>
                </div>
                <div class="dropdown d-inline-block pl-3 ml-2 has-bullet-separator">
                    <a 
                        href="javascript:void(0);"
                        id="sortStatusDropdown"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <ar-button 
                            class="position-relative font-weight-medium pl-3 a-btn-text"
                            style="font-size: 12px; text-align:center; padding-left: 12px !important; padding-right: 12px; padding-top: 3px; padding-bottom: 3px; margin-top: 2px;">
                            <img src="/static/svg/notifications/Sort_Researcher.svg" class="position-relative" style="top: -1px" alt="icon">
                            Sort by {{ selectedFilter.status == 'Researched' ? 'For Quality Checking': (selectedFilter.status == 'Recycled' && access() == 6) ? 'Accepted' : 
                                        selectedFilter.status == 'Invalid' ? 'All Invalid' : selectedFilter.status == 'archived' ? 'Archived' : selectedFilter.status
                                    }}
                        </ar-button>
                    </a>
                    <div class="dropdown-menu" aria-labelledby="sortStatusDropdown" style="height: 300px;overflow:auto;">
                        <a
                            class="dropdown-item"
                            href="javascript:void(0);"
                            @click="filters.status.selected = 0"
                        >
                            All Status
                        </a>
                        <a
                            v-for="(option, index) in filtered_status"
                            :key="`sort-status--${index}`" 
                            class="dropdown-item mb-1"
                            href="javascript:void(0);"
                            @click="filters.status.selected = option.value"
                        >
                            {{ 
                                option.label == 'Researched' ? 'For Quality Checking' : (option.label == 'Recycled' && access() == 6) ? 'Accepted' : 
                                option.label == 'Invalid' ? 'All Invalid' : option.label == 'archived' ? 'Archived' : option.label
                            }}
                        </a>
                    </div>
                </div>
                <div class="dropdown d-inline-block pl-3 ml-2 has-bullet-separator">
                    <a 
                        href="javascript:void(0);"
                        id="orderDropdown"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <ar-button 
                            class="position-relative font-weight-medium pl-3 a-btn-text"
                            style="font-size: 12px; text-align:center; padding-left: 12px !important; padding-right: 12px; padding-top: 3px; padding-bottom: 3px; margin-top: 2px;">
                            <img src="/static/svg/counterfeit/Order_Ascending.svg" class="position-relative" style="top: -1px" alt="icon">
                            Order By {{ selectedFilter.order }}
                        </ar-button>
                    </a>
                    <div class="dropdown-menu" aria-labelledby="orderDropdown">
                        <a
                            v-for="(option, index) in filters.order.data"
                            :key="`order-by--${index}`" 
                            class="dropdown-item mb-1"
                            href="javascript:void(0);"
                            @click="filters.order.selected = option.value"
                        >
                            {{ option.label }}
                        </a>
                    </div>
                </div> 
                <div v-if="!defaultFilters" class="d-inline-block pl-3 ml-2 has-bullet-separator">
                    <ar-button 
                        @click="resetFilters()" 
                        class="position-relative font-weight-medium pl-3 a-btn-text btn-text-invalid"
                        style="font-size: 12px; text-align:center; padding-left: 12px !important; padding-right: 12px; padding-top: 3px; padding-bottom: 3px; margin-top: 2px;"
                    >
                        Reset
                    </ar-button>
                </div>
            </div>
        </div>

        <ar-dialog
            id="import-listing-modal"
            large
            :xLarge="!!add.response.length"
            custom_width="100%"
            :has_button="false"
            :has_footer="false"
        >
            <div class="row text-left justify-content-between">
                <template v-if="add.response.length">
                    <h5 class="d-inline-block ml-2 align-middle">
                        Import Data Summary
                        <span class="d-block font-weight-normal">Below are the successful and unsuccessful data inserted in the system or in the counterfeit data.</span>
                    </h5>
                    <table class="table-scroll mb-2">
                        <thead>
                            <tr>
                                <th class="col-md-2" scope="col">Import Status</th>
                                <th class="col-md-2" scope="col">Product URL</th>
                                <th class="col-md-2" scope="col">Store URL</th>
                                <th class="col-md-2" scope="col">Status</th>
                                <th class="col-md-2" scope="col">Message</th>
                                <th class="col-md-2" scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in add.response" :key="index">
                                <!-- IMPORT STATUS -->
                                <td class="col-md-2 text-bold" scope="row">
                                    <span class="status" :class="{'not_found': !item.success}">{{ ['Not Imported', 'Imported'][+item.success] }}</span>
                                </td>
                                <!-- Product URL -->
                                <td class="col-md-2">
                                    <span class="d-block text-truncate">
                                        <template v-if="((item || {}).listing || {}).url || item.product_url">
                                            <a target="_blank" :href="((item || {}).listing || {}).url || item.product_url" :title="((item || {}).listing || {}).url || item.product_url">{{ ((item || {}).listing || {}).url || item.product_url }}</a>
                                        </template>
                                        <template v-else>
                                            No Value
                                        </template>
                                    </span>
                                </td>
                                <!-- Store URL -->
                                <td class="col-md-2">
                                    <span class="d-block text-truncate">
                                        <template v-if="((item || {}).listing || {}).url || item.store_url">
                                            <a target="_blank" :href="((item || {}).listing || {}).store_url || item.store_url" :title="((item || {}).listing || {}).store_url || item.store_url">
                                                {{ ((item || {}).listing || {}).store_url || item.store_url }}
                                            </a>
                                        </template>
                                        <template v-else>
                                            No Value
                                        </template>
                                    </span>
                                </td>
                                <!-- Status -->
                                <td class="col-md-2">
                                    <span class="status" 
                                        :class="itemStatusClass(item)"
                                    >
                                        {{ (itemStatus(item) == 'Recycled' && access() == 6) ? 'Accepted' : itemStatus(item) }}
                                    </span>
                                </td>
                                <td class="col-md-2">
                                    <span class="d-block text-truncate" :title="item.message">{{ item.message }}</span>
                                </td>
                                <td class="col-md-2" 
                                    v-if="['Monitoring', 'Qualified', 'Rejected', 'Accepted', 'Recycled'].includes(item.listing?.status ?? item.status)">
                                    <a
                                        v-if="(item.listing?.status ?? item.status) !== 'Monitoring'"
                                        href="javascript: void(0);"
                                        data-toggle="modal"
                                        data-target="#edit-status-modal"
                                        @click="hideListingSummary();changeListingStatus(item, 31)"
                                        class="mr-3 action-item cm-status-change-monitoring"
                                        v-tooltip="$t(`Mark as Monitoring`)"
                                    >
                                        <img src="/static/svg/Action_Monitor.svg" alt="formonitoring">
                                    </a>
                                    <a
                                        v-if="!['Accepted', 'Recycled'].includes(item.listing?.status ?? item.status)"
                                        href="javascript:void(0);"
                                        data-toggle="modal"
                                        data-target="#edit-status-modal"
                                        @click="hideListingSummary(); changeListingStatus(item, 32)"
                                        class="mr-3 action-item cm-status-change-accepted"
                                        v-tooltip="$t(`Mark as Accepted`)"
                                    >
                                        <img src="/static/svg/Action_Accept.svg" alt="accept">
                                    </a>
                                    <a
                                        v-if="(item.listing?.status ?? item.status) !== 'Rejected'"
                                        href="javascript: void(0);"
                                        data-toggle="modal"
                                        data-target="#edit-status-modal"
                                        @click="hideListingSummary(); changeListingStatus(item, 33)"
                                        class="mr-3 action-item cm-status-change-rejected"
                                        v-tooltip="$t(`Mark as Rejected`)"
                                    >
                                        <img src="/static/svg/Action_Reject.svg" alt="reject">
                                    </a>
                                </td>
                                <td class="col-md-2" v-else>
                                    <span>No Action</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="btn-con float-right mt-3">
                        <ar-button 
                            :disabled="!isListingSummaryDismissable"
                            outlined 
                            data-dismiss="modal"
                            style="color: #202229; border-color: #202229;"
                            v-tooltip="isListingSummaryDismissable ? '' : $t('1 or more Data under Qualified, Monitoring & Rejected is not yet moved to another status')"
                        >Go Back</ar-button>
                        <ar-button
                            :disabled="!isListingSummaryDismissable"
                            class="ml-2"
                            @click="resetImportResponse()"
                            v-tooltip="isListingSummaryDismissable ? '' : $t('1 or more Data under Qualified, Monitoring & Rejected is not yet moved to another status')"
                        >
                            Add More
                        </ar-button>
                    </div>
                </template>
                <template v-else-if="!add.import.columns_selection">
                    <div class="col-md-6 pr-4 border-right">
                        <template v-if="add.current">
                            <div class="add-img-con text-center">
                                <img src="/static/svg/counterfeit/Manual_addition.svg" alt="manual addition">
                                <ar-button class="ml-2 mt-3" a_class="accent" @click="updateAddCurrent()">
                                    Choose this Option
                                </ar-button>
                            </div>
                        </template>
                        <template v-else>
                            <template v-if="add.manual.duplicates.length || add.manual.invalid_url.length">
                                <div class="left-con">
                                    <div :class="{'pr-3': add.manual.duplicates.length > 1 }" :style="`${add.manual.duplicates.length ? 'word-break: break-word;' : ''}`" style="height: 438px; overflow-y: auto;">
                                        <template v-if="add.manual.duplicates.length">
                                            <div class="mb-2">
                                                <h5 class="d-inline-block ml-2 align-middle">
                                                    {{ add.manual.duplicates.length + " Duplicate" + (add.manual.duplicates.length > 1 ? 's' : '') + ' found' }}
                                                    <span class="d-block font-weight-normal" v-html="addModalSubtitle"></span>
                                                </h5>
                                            </div>
                                            <div v-for="(dup, index) in add.manual.duplicates" :key="`duplicate-url-${index}`">
                                                <label class="font-weight-medium ml-2 mb-0" style="color: #dc1c1c; font-size: 14px;">{{ dup }}</label>
                                                <hr style="border: 1px dashed #2D599E" v-if="add.manual.duplicates.length > 1 && (add.manual.duplicates.length - 1) != index">
                                            </div>
                                        </template>
                                        <template v-if="add.manual.invalid_url.length">
                                            <div class="mb-2 mt-4">
                                                <h5 class="d-inline-block ml-2 align-middle">
                                                    {{ add.manual.invalid_url.length + " Invalid " + (add.manual.invalid_url.length > 1 ? 's' : '') + ' found' }}
                                                    <span class="d-block font-weight-normal" v-html="addInvalidURLModalSubtitle"></span>
                                                </h5>
                                            </div>
                                            <div v-for="(dup, index) in add.manual.invalid_url" :key="`invalid-url-${index}`">
                                                <label class="font-weight-medium ml-2 mb-0" style="color: #dc1c1c; font-size: 14px;">{{ dup }}</label>
                                                <hr style="border: 1px dashed #2D599E" v-if="add.manual.invalid_url.length > 1 && (add.manual.duplicates.length - 1) != index">
                                            </div>  
                                        </template>
                                    </div>
                                    <div class="btn-con float-right mt-3">
                                        <ar-button 
                                            outlined 
                                            data-dismiss="modal"
                                            style="color: #202229; border-color: #202229;"
                                        >Cancel</ar-button>
                                        <ar-button class="ml-2" @click="resetManual()">
                                            Go Back
                                        </ar-button>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="mb-2">
                                    <h5 class="d-inline-block ml-2 align-middle">
                                        Manual Addition
                                        <span class="d-block font-weight-normal" v-html="addModalSubtitle"></span>
                                    </h5>
                                </div>
                                <div class="left-con">
                                    <div style="height: 362px; overflow-y: auto;" :class="{'pr-3': add.manual.data.length > 1}">                                        
                                        <div v-for="(manual, index) in add.manual.data" :key="index">
                                            <div class="d-flex justify-content-between align-items-center" style="font-size: 13px;" v-if="add.manual.data.length > 1">
                                                <div>
                                                    <label class="font-weight-medium mt-1" style="color: #2D599E">DATA {{ index + 1 }}</label>
                                                </div>
                                                <div>
                                                    <a href="javascript:void(0);" class="d-inline-block text-uppercase text-danger" @click="updateManualAdditionData(index)">
                                                        <img src="/static/svg/Remove_List.svg" alt="icon">
                                                        <u class="ml-1">Remove</u>
                                                    </a>
                                                </div>
                                            </div>
                                            <ar-textfield 
                                                placeholder         ="Input here"
                                                :a_class            ="!manual.productUrl ||checkURL.isNotValid.includes(JSON.stringify({ index, inner: 2 })) || checkURL.hasDuplicate.includes(JSON.stringify({ index, inner: 2 })) ? 'mb-0' : ''"
                                                a_type              ="textarea"
                                                a_style             ="resize: none; height:66px;"
                                                label               ="Product URL"
                                                v-model.trim        ="manual.productUrl"
                                                :err_txt            ="manual.productUrl && checkURL.isNotValid.includes(JSON.stringify({ index, inner: 2 })) ? 'Invalid URL' : checkURL.hasDuplicate.includes(JSON.stringify({ index, inner: 2 })) ? 'URL already entered' : ''"
                                                :state              ="(manual.productUrl && checkURL.isNotValid.includes(JSON.stringify({ index, inner: 2 }))) || checkURL.hasDuplicate.includes(JSON.stringify({ index, inner: 2 })) ? 'invalid' : ''"
                                            />
                                            <h6 
                                                id="manual-cc-addition-store-url"
                                                style="cursor:pointer; border-top: 1px solid #eee"
                                                class="d-flex justify-content-between align-items-center pt-3 pl-1 pr-1"
                                                data-toggle="collapse"
                                                :data-target="`#collapseManualCCAdditionStoreURL${index}`"
                                                title="Click to show/hide store url">
                                                <div class="col-form-label p-0">
                                                    Store URL (Optional)
                                                </div>
                                                <i class="d-block text-muted fas fa-ellipsis-h"></i>
                                            </h6>
                                            <nav class="mt-2 collapse mb-3" :id="`collapseManualCCAdditionStoreURL${index}`" aria-labelledby="manual-cc-addition-store-url">
                                                <ar-textfield 
                                                    :disabled           ="add.manual.checking"
                                                    placeholder         ="Input here"
                                                    a_class             ="mb-0"
                                                    a_type              ="textarea"
                                                    a_style             ="resize: none; height:66px;"
                                                    v-model.trim        ="manual.storeUrl"
                                                    :err_txt            ="manual.storeUrl && checkURL.isNotValid.includes(JSON.stringify({ index, inner: 1 })) ? 'Invalid URL' : checkURL.hasDuplicate.includes(JSON.stringify({ index, inner: 1 })) ? 'URL already entered' : ''"
                                                    :state              ="manual.storeUrl && checkURL.isNotValid.includes(JSON.stringify({ index, inner: 1 })) || checkURL.hasDuplicate.includes(JSON.stringify({ index, inner: 1 })) ? 'invalid' : ''"
                                                />
                                            </nav>
                                            <!-- <ar-select 
                                                :val="manual.claimtype"
                                                v-model="manual.claimtype"
                                                :options="filters.claimtype.data"
                                                label="Claim Type"
                                            >
                                                Select here
                                            </ar-select> -->
                                            <hr class="mt-4 mb-3" style="border: 1px dashed #2D599E" v-if="add.manual.data.length > 1 && (add.manual.data.length - 1) != index">
                                        </div>
                                        <a href="javascript:void(0);" style="font-size: 13px; color: #2D599E" class="d-inline-block float-right text-uppercase" @click="updateManualAdditionData()">
                                            <u>Add more data</u>
                                        </a>
                                    </div>
                                    <div class="btn-con float-right mt-3">
                                        <ar-button 
                                            outlined 
                                            data-dismiss="modal"
                                            style="color: #202229; border-color: #202229;"
                                        >Cancel</ar-button>
                                        <ar-button v-if="add.manual.duplicates.length || add.manual.invalid_url.length" class="ml-2" @click="resetManual()">
                                            Go Back
                                        </ar-button>
                                        <ar-button v-else class="ml-2" @click="addListing()" :disabled="hasEmptyInvalidURL || add.manual.checking">
                                            {{ add.manual.checking ? "Validating.." : "Validate Now" }}
                                        </ar-button>
                                    </div>
                                </div>
                            </template>
                        </template>
                    </div>
                    <div class="col-md-6 pl-4">
                        <template v-if="add.current">
                            <template v-if="add.import.duplicates.length || add.import.invalid_url.length">
                                <div class="left-con">
                                    <div :class="{'pr-3': add.import.duplicates.length > 1 }" :style="`${add.import.duplicates.length ? 'word-break: break-word;' : ''}`" style="height: 438px; overflow-y: auto;">
                                        <template v-if="add.import.duplicates.length">
                                            <div class="mb-2">
                                                <h5 class="d-inline-block ml-2 align-middle">
                                                    {{ add.import.duplicates.length + " Duplicate" + (add.import.duplicates.length > 1 ? 's' : '') + ' found' }}
                                                    <span class="d-block font-weight-normal" v-html="addModalSubtitle"></span>
                                                </h5>
                                            </div>
                                            <div v-for="(dup, index) in add.import.duplicates" :key="`import-duplicate-url-${index}`">
                                                <label class="font-weight-medium ml-2 mb-0" style="color: #dc1c1c; font-size: 14px;">{{ dup }}</label>
                                                <hr style="border: 1px dashed #2D599E" v-if="add.import.duplicates.length > 1 && (add.import.duplicates.length - 1) != index">
                                            </div>
                                        </template>
                                        <template v-if="add.import.invalid_url.length">
                                            <div class="mb-2 mt-4">
                                                <h5 class="d-inline-block ml-2 align-middle">
                                                    {{ add.import.invalid_url.length + " Invalid " + (add.import.invalid_url.length > 1 ? 's' : '') + ' found' }}
                                                    <span class="d-block font-weight-normal" v-html="addInvalidURLModalSubtitle"></span>
                                                </h5>
                                            </div>
                                            <div v-for="(dup, index) in add.import.invalid_url" :key="`import-invalid-url-${index}`">
                                                <label class="font-weight-medium ml-2 mb-0" style="color: #dc1c1c; font-size: 14px;">{{ dup }}</label>
                                                <hr style="border: 1px dashed #2D599E" v-if="add.import.invalid_url.length > 1 && (add.import.duplicates.length - 1) != index">
                                            </div>  
                                        </template>
                                    </div>
                                    <div class="btn-con float-right mt-3">
                                        <ar-button 
                                            outlined 
                                            data-dismiss="modal"
                                            style="color: #202229; border-color: #202229;"
                                        >Cancel</ar-button>
                                        <ar-button class="ml-2" @click="resetUpload()">
                                            Go Back
                                        </ar-button>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="animated fadeIn" v-show="!add.import.multiple_sheets_detected">
                                    <label class="mb-0 d-block font-weight-medium" style="color: #2D599E; font-size: 16px;">Generate Spreadsheet form</label>
                                    <p style="font-size: 13px;">Download a source file to be used for import. If you're done with this process, please proceed with uploading source file.</p>
                                    <ar-button
                                        @click="generate()"
                                        class="mr-2 btn-primary"
                                        :disabled="add.import.generating"
                                        outlined>
                                        <img src="/static/svg/defendants/Button_GenerateFile.svg" class="position-relative mr-1" style="top: -2px;" alt="generate"> {{add.import.generating ? 'Generating...' : 'Generate File'}}</ar-button>
                                </div>
                                <h5 class="mt-4 mb-3" style="font-size: 15px; color: #515365;">Import sheet <span class="d-block font-weight-normal" style="font-size: 12px;">Upload an excel sheet with <b class="text-primary font-weight-medium">Product URL</b>, <b class="text-primary font-weight-medium">Store URL</b>, and <b class="text-primary font-weight-medium">Claim Type</b> columns. Either the Product URL or Store URL is required for the import.</span></h5>
                                <div :class="{'mb-5': !add.import.multiple_sheets_detected}" style="max-height:324px;">
                                    <template v-if="!add.import.file_name || add.import.uploading">
                                        <div v-tooltip="`<i class='fas fa-question-circle text-muted mr-2'></i>Specify what row should be the start of the links on the imported file`">
                                            <label class="mr-3 ml-1 font-weight-medium" style="font-size: 12px; color: #383A3D;">Start from row: </label>
                                            <ar-textfield 
                                                id             ="row-index" 
                                                a_type         ="number"
                                                placeholder    ="Select Row Index" 
                                                min            ="0"
                                                @keypress.native="isNumberOnly"
                                                v-model ="add.import.start" 
                                            />
                                        </div>
                                        <div class="custom-control custom-checkbox d-inline-block ml-1 sm" :class="{'disabled': add.import.uploading}" v-tooltip="`<i class='fas fa-question-circle text-muted mr-2'></i>Toggle if the file you will upload has headers or not`">
                                            <input class="custom-control-input" type="checkbox" name="sheet-with-header" id="sheet-with-header" v-model="add.import.withHeaders" :value="1" :disabled="add.import.uploading">
                                            <label for="sheet-with-header" class="custom-control-label font-weight-normal" style="font-size: 14px; padding-left: 10px;">Does the file have headers?</label>
                                        </div>
                                    </template>
                                    <ar-textfield
                                        id      ="import-listings-field"
                                        a_type  ="file"
                                        a_class ="text-left text-truncate mt-1 mb-1"
                                        :style="add.import.file_name && !add.import.uploading ? 'display: inline-block; vertical-align:middle; width: 196px' : ''"
                                        :label  ="this.add.import.file_name || 'Choose File'"
                                        :err_txt="add.import.error ? add.import.error : ''"
                                        :state  ="add.import.error ? 'invalid' : ''"
                                        @change ="viewSourceFile"
                                        :disabled="add.import.file_name != ''"
                                    />
                                    <template v-if="add.import.file_name && !add.import.uploading">
                                        <ar-button class="font-weight-medium d-inline-block align-middle btn-info ml-3" style="height: 24px; width: 110px; font-size: 10px; padding: 0; margin-top: -12px;" @click="resetUpload()">Upload Another</ar-button>
                                    </template>
                                    <div v-show="add.import.multiple_sheets_detected" class="mt-5">
                                        <p style="font-size: 12px; margin-bottom: 10px;"><span class="d-block font-weight-medium" style="color: #F2611C;">{{ sheetsDetectedLabel }}</span> Please select what to choose from to continue</p>
                                        <div style="height: 157px; overflow-y: auto;" class="pl-3">
                                            <ar-radio
                                                v-for="(sheet, index) in add.import.sheets.options"
                                                :key="index"
                                                :label="sheet.label"
                                                :id="`sheet-${index}`"
                                                name="sheets-found"
                                                class="mb-2 sm"
                                                @change="add.import.sheets.selected = sheet.value"
                                                :checked="add.import.sheets.selected == sheet.value"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="btn-con float-right" :style="add.import.multiple_sheets_detected ? 'margin-top: 15px;' : 'margin-top: 1px;'">
                                    <ar-button 
                                        outlined 
                                        data-dismiss="modal"
                                        style="color: #202229; border-color: #202229;"
                                    >Cancel</ar-button>
                                    <ar-button class="ml-2" @click="getImportColumn()" :disabled="!add.import.file_name || add.import.uploading">
                                        Add Now
                                    </ar-button>
                                </div>
                            </template>
                        </template>
                        <template v-else>
                            <div class="add-img-con text-center">
                                <img src="/static/svg/counterfeit/Import_sheet.svg" alt="import sheet">
                                <ar-button class="ml-2 mt-3" a_class="accent" @click="updateAddCurrent(); isImportSheetOption = true">
                                    Choose this Option
                                </ar-button>
                            </div>
                        </template>
                    </div>
                </template>
                <template v-else>
                    <div class="animated fadeIn w-100" id="import-sheet">
                        <h5 class="text-left">Import Sheet <span class="d-block">Associate source columns to destination fields in order to start reading data.</span></h5>
                        <table class="table-scroll mt-3">
                            <thead>
                                <tr>
                                    <th class="col-md-1 text-center" scope="col"></th>
                                    <th class="col-md-4 text-left" scope="col">Source Columns</th>
                                    <th class="col-md-3 text-center" scope="col"></th>
                                    <th class="col-md-4 text-center" scope="col">Destination field</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(column, index) in Object.values(add.import.columns.data)" :class="{'border-bottom-0': index == Object.values(add.import.columns.data).length - 1}" :key="index">
                                    <td class="col-md-1 text-center"></td>
                                    <td class="col-md-4 text-left">{{ column.label }}</td>
                                    <td class="col-md-3 text-center"></td>
                                    <td class="col-md-4 text-center p-1 pr-3 pl-3">
                                        <ar-select 
                                            :val="column.associated"
                                            v-model="column.associated"
                                            :options="add.import.options"
                                            @change="checkAssociatedColumns(index)"
                                            class="mb-0"
                                            :disabled="add.import.checking"
                                        >
                                            Select here
                                        </ar-select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <hr class="mt-3"/>
                        <div class="btn-con d-flex justify-content-between" style="margin-top: 15px">
                            <div>
                                <ar-button 
                                    @click="backFromColumnSelect()"
                                    outlined 
                                    data-dismiss="modal"
                                    style="color: #202229; border-color: #202229;"
                                    :disabled="add.import.checking"
                                ><i class="fas fa-arrow-left mr-2"></i>Back</ar-button>
                            </div>
                            <div>
                                <ar-button 
                                    outlined 
                                    data-dismiss="modal"
                                    style="color: #202229; border-color: #202229;"
                                    :disabled="add.import.checking"
                                >Cancel</ar-button>
                                <ar-button class="ml-2" @click="addListing(true)" :disabled="!hasAssociatedColumnSelected || add.import.checking">
                                    {{ add.import.checking ? 'Validating..' : 'Add Now' }}
                                </ar-button>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </ar-dialog>

        <ar-dialog
            id="matches-found-modal"
            custom_maxwidth="100%"
            custom_width="1100px"
            :has_button="false"
            :has_footer="false"
        >
            <h5 class="text-left">Matches Found <span>Seller already exists in the system.</span></h5>
            <div class="text-right">
                <div class="custom-control custom-checkbox d-inline-block" v-tooltip="`<i class='fas fa-question-circle text-muted mr-2'></i>Toggle this to set the actions of the found matches to Use Product`">
                    <input class="custom-control-input" type="checkbox" name="match-options" id="match-option-use-product" v-model="add.matches_found.bulk_action" :value="1" @change="checkMatchAction(1)">
                    <label for="match-option-use-product" class="custom-control-label font-weight-normal" style="font-size: 14px;">Use all Products</label>
                </div>
                <div class="custom-control custom-checkbox d-inline-block ml-3" v-tooltip="`<i class='fas fa-question-circle text-muted mr-2'></i>Toggle this to set the actions of the found matches to Skip`">
                    <input class="custom-control-input" type="checkbox" name="match-options" id="match-option-skip-all" v-model="add.matches_found.bulk_action" :value="2" @change="checkMatchAction(2)">
                    <label for="match-option-skip-all" class="custom-control-label font-weight-normal" style="font-size: 14px;">Skip all</label>
                </div>
            </div>
            <table class="table-scroll mt-3">
                <thead>
                    <tr>
                        <th class="col-md-2 text-center" scope="col">Status</th>
                        <th class="col-md-2 text-center" scope="col">Listing ID</th>
                        <th class="col-md-2 text-center" scope="col">Item Type</th>
                        <th class="col-md-2 text-center" scope="col">Product Title</th>
                        <th class="col-md-2 text-center" scope="col">Product URL</th>
                        <th class="col-md-2 text-center" scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(found, index) in add.matches_found.data" :key="index" class="text-left position-relative">
                        <i v-if="found.listing_details.length > 1" class="fas fa-info-circle position-absolute" style="left: 15px; top: 50%; transform: translateY(-50%); z-index: 999; cursor: help;" v-tooltip="'This is a group of matches with the same seller url or the same product url. You can click on the details to choose what product you will use or either skip.'"></i>
                        <div :style="`${found.listing_details.length > 1 ? 'cursor: pointer;' : ''}`" class="col-md-10 d-inline-block text-left align-middle" :class="{'match-found-listing': found.listing_details.length > 1, 'active': found.selected == iindex}" v-for="(details, iindex) in found.listing_details" :key="iindex" @click="found.selected = iindex" v-tooltip="`${found.listing_details.length > 1 ? 'Click to choose this match to use or skip' : ''}`">
                            <td class="col-md-2 text-center">
                                <img style="width: 10px; position:relative; top:-2px; margin:0 4px 0 0" :src="$parent.getQFlagIcon(details.qflag_id)" alt="qflag"> 
                                {{ ["Qualified", "Hacked", "Recheck"].includes(details.status) ? getAltStatus(details.status) : details.status }}
                            </td>
                            <td class="col-md-3 text-center"><span class="w-100 d-block text-truncate" v-tooltip="details.listing_id">{{ details.listing_id }}</span></td>
                            <td class="col-md-2 text-center"><span class="w-100 d-block text-truncate" v-tooltip="details['item type']">{{ details["item type"] || "No Item Type" }}</span></td>
                            <td class="col-md-3 text-center"><b class="w-100 d-block text-truncate" v-tooltip="details['product title']">{{ details['product title'] }}</b></td>
                            <td class="col-md-2 text-center">
                                <v-popover trigger="hover" placement="top">
                                    <u><a :href="details['product url']" target="_blank">Link here</a></u>
                                    <template #popover>
                                        <img src="/static/svg/Copy Link.svg" alt="icon" class="d-inline-block align-middle">
                                        <a :href="details['product url']" class="text-truncate ml-2 mr-2 d-inline-block align-middle" style="max-width: 130px;">{{ details['product url'] }}</a>
                                        <ar-button class="font-weight-medium d-inline-block align-middle" style="height: 24px; width: 80px; font-size: 10px; padding: 0" @click="copytoClipboard(details['product url'])">Copy Link</ar-button>
                                    </template>
                                </v-popover>
                            </td>
                        </div>
                        <td class="col-md-2 text-center p-1 pr-3 pl-3 align-middle position-absolute" style="top: 50%; transform: translateY(-50%)">
                            <ar-select 
                                :val="found.action"
                                v-model="found.action"
                                :options="add.matches_found.options"
                                class="mb-0"
                                @change="individualSetAction()"
                            >
                                Set here
                            </ar-select>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="btn-con float-right" style="margin-top: 15px">
                <ar-button 
                    outlined 
                    data-dismiss="modal"
                    style="color: #202229; border-color: #202229;"
                >Cancel</ar-button>
                <ar-button class="ml-2" :disabled="hasNoActionSelected || add.adding" @click="addListingMatch()">
                    Add Now
                </ar-button>
            </div>
        </ar-dialog>

        <ar-dialog
            id="listing-details-modal"
            dialog_style="padding: 10px; padding-top: 0;"
            large
            :has_button="false"
            :has_footer="false"
        >
            <div class="text-left d-flex justify-content-between align-items-center">
                <ar-button
                    a_class="a-btn-text mr-2"
                    data-dismiss="modal"
                    icon="fas fa-chevron-left">
                    <span>Back to {{campaign_data.name}}</span>
                </ar-button>
                <div class="d-flex">
                    <ar-button 
                        v-if="![6, 3].includes(this.access()) && currentStatus.label == 'Invalid'"
                        a_class="a-btn-text mr-2"
                        icon="fas fa-redo"
                        @click="details.invalidHistory = !details.invalidHistory"
                    >
                        Status History
                    </ar-button>
                    <ar-button 
                        v-if="this.access() != 6 && currentStatus.label !== 'Invalid'"
                        @click="details.commentTab = !details.commentTab, 
                                details.invalidNote = false"
                        a_class="a-btn-text mr-2"
                        :icon="details.commentTab ? 'fas fa-redo' : 'far fa-comment-alt'"
                    >
                        {{ details.commentTab ? 'Status History' : 'Show Comments' }}
                    </ar-button>
                    <ar-button
                        v-if="currentStatus.label == 'Invalid'"
                        @click="details.invalidNote = !details.invalidNote, 
                                details.commentTab = !details.commentTab,
                                details.invalidHistory = false"
                        a_class="a-btn-text mr-2"
                        :class="{ 'invalid-note-btn' : !details.invalidNote }"
                        :icon="details.invalidNote ? 'far fa-comment-alt' : 'fas fa-times-circle'"
                    >
                        {{ details.invalidNote ? 'Show Comments' : 'Show Invalid Notes' }}
                    </ar-button>
                </div>
            </div>
            <hr class="mt-2 mb-1"/>
            <div class="row text-left justify-content-between">
                <div class="col-md-6 pr-4 left-outer-con d-flex justify-between flex-column">
                    <div>
                        <div class="mb-3 row align-items-center">
                            <!-- <img src="/static/svg/counterfeit/Counterfeit_Checker.svg" class="mr-1 counter-image" alt="icon"> -->
                            <div class="mr-1 listing-preview-counter-image">
                                <img v-lazy="getPreview()" onerror="this.src='/static/svg/Listing Default Photo.svg'" alt="icon">
                            </div>
                            <h5 class="d-inline-block ml-2 align-middle text-truncate">
                                {{ listingOwnerName }}
                                <span class="mt-1 d-block font-weight-normal" v-if="details.employee.id">
                                    <img src="/static/svg/counterfeit/Assign_Researcher.svg" class="position-relative mr-2" style="top: -1px" alt="icon">
                                    Assigned To: <b>{{ details.employee.name }}</b>
                                </span>
                                <span :class="{'mt-1': !details.employee.id}" class="d-block font-weight-normal">
                                    <img src="/static/svg/counterfeit/Date_Added.svg" class="position-relative mr-2" style="top: -1px" alt="icon">
                                    Date Added: <b>{{ details.datetime }}</b>
                                </span>
                            </h5>
                        </div>
                        <div class="left-con pr-2 pb-3" :style="`${[3,6].includes(access()) ? 'height: 365px' : ''}`">
                            <div class="row mb-2">
                                <div class="col-md-5">
                                    <img src="/static/svg/ListingInfo_SellerURL.svg" alt="icon"> Provided Product:
                                </div>
                                <div class="col-md-7">
                                    <template v-if="details.listing_url">
                                        <a class="text-break line-clamp font-weight-medium" :href="details.listing_url" target="_blank">
                                            {{ details.listing_url }}
                                        </a>
                                    </template>
                                    <template v-else>
                                        No Value
                                    </template>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-5">
                                    <img src="/static/svg/ListingInfo_SellerURL.svg" alt="icon"> Provided Store:
                                </div>
                                <div class="col-md-7">
                                    <template v-if="details.store_url">
                                        <a class="text-break line-clamp font-weight-medium" :href="details.store_url" target="_blank">
                                            {{ details.store_url }}
                                        </a>
                                    </template>
                                    <template v-else>
                                        No Value
                                    </template>
                                </div>
                            </div>
                            <div class="row mb-2 align-items-center">
                                <div class="col-md-5">
                                    <img src="/static/svg/Trademark_Claim.svg" alt="icon"> Claim Type:
                                </div>
                                <div class="col-md-7">
                                    <template v-if="canEdit">
                                        <ar-select 
                                            :val="details.claimtype"
                                            v-model="details.claimtype"
                                            :options="filters.claimtype.data"
                                            class="mb-0"
                                        >
                                            Select here
                                        </ar-select>
                                    </template>
                                    <template v-else>
                                        <b>{{ currentClaimtype || "No Claimtype" }}</b>
                                    </template>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-5">
                                    <img src="/static/svg/SellerInfo_Platform.svg" alt="icon"> Listing ID:
                                </div>
                                <div class="col-md-7">
                                    <b>{{ details.listing_id }}</b>
                                </div>
                            </div>
                            <div class="row mb-2" :class="{'mb-4 align-items-center': showLIDField}">
                                <div class="col-md-5">
                                    <img src="/static/svg/SellerInfo_Platform.svg" alt="icon"> Status:
                                </div>
                                <div class="col-md-7">
                                    <template v-if="canEdit">
                                        <ar-select 
                                            :val="details.status"
                                            v-model="details.status"
                                            :options="getStatusOptions(details.status)"
                                            class="mb-0"
                                            first_element_as_default
                                        >
                                            Select here
                                        </ar-select>
                                    </template>
                                    <template v-else>
                                        <span :class="`info-status ${currentStatus.state}` ">
                                            {{ currentStatus.label == "Qualified" ? "For Review" : 
                                                currentStatus.label == "Researched" ? "For Quality Checking" : 
                                                (currentStatus.label == "Recycled" && access() == 6) ? "Accepted" : 
                                                currentStatus.label == 'Invalid' ? details?.invalidReasons[0]?.main : currentStatus.label }}
                                        </span>
                                    </template>
                                </div>
                            </div>
                            <!-- <div class="row" :class="{'align-items-center': showLIDField}" v-if="showLIDField">
                                <div class="col-md-5">
                                    <img src="/static/svg/SellerInfo_Platform.svg" alt="icon"> Listing ID:
                                </div>
                                <div class="col-md-7">
                                    <template v-if="canEdit">
                                        <ar-textfield
                                            id="existing-lid"
                                            placeholder         ="Input here"
                                            @keypress.native    ="isNumber"
                                            @paste.native       ="cleanPasteInput"
                                            v-model.number.trim ="details.insystem.value"
                                            class               ="mb-0"
                                        />
                                    </template>
                                    <template v-else>
                                        <b>{{ details.insystem.data.listing_id }}</b>
                                    </template>
                                </div>
                            </div> -->
                            <div v-if="showInSystemDetails" :key="details.insystem.key">
                                <hr/>
                                <!-- <div class="row mb-4">
                                    <div class="col-md-5">
                                        <img src="/static/svg/Case_PlatformID.svg" alt="icon"> Research Status:
                                    </div>
                                    <div class="col-md-7">
                                        <b>{{ details.insystem.data.listing_status }}</b>
                                    </div>
                                </div> -->
                                <!-- <div class="row mb-2 align-items-center">
                                    <div class="col-md-5">
                                        <img src="/static/svg/ListingInfo_SampleDummy.svg" alt="icon"> Seller Name:
                                    </div>
                                    <div class="col-md-7">
                                        <b>{{ details.insystem.data.seller_name }}</b>
                                    </div>
                                </div> -->
                                <div class="row mb-2">
                                    <div class="col-md-5">
                                        <img src="/static/svg/ListingInfo_SampleDummy.svg" alt="icon"> Seller Name:
                                    </div>
                                    <div class="col-md-7">
                                        <a class="text-break line-clamp font-weight-medium" :href="details.insystem.data.seller_url" target="_blank">
                                            {{ details.insystem.data.seller_name || 'No Seller Name' }}
                                        </a>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-5">
                                        <img src="/static/svg/ListingInfo_SellerURL.svg" alt="icon"> Platform:
                                    </div>
                                    <div class="col-md-7">
                                        <a class="text-break line-clamp font-weight-medium" :href="this.details.insystem.data.listing_url" target="_blank">
                                            <b>{{ details.insystem.data.platform }}</b>
                                        </a>
                                    </div>
                                </div> 
                                <div class="row">
                                    <div class="col-md-5">
                                        <img src="/static/svg/Conversation_Script.svg" alt="icon"> Product Title:
                                    </div>
                                    <div class="col-md-7">
                                        <b>{{ details.insystem.data.listing_title }}</b>
                                    </div>
                                </div> 
                                <!-- <div class="row mb-2">
                                    <div class="col-md-5">
                                        <img src="/static/svg/ListingInfo_ItemType.svg" alt="icon"> Product URL:
                                    </div>
                                    <div class="col-md-7">
                                        <a class="text-break line-clamp font-weight-medium" :href="details.insystem.data.listing_url" target="_blank">
                                            {{ details.insystem.data.listing_url }}
                                        </a>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5">
                                        <img src="/static/svg/ListingInfo_SellerURL.svg" alt="icon"> Store URL:
                                    </div>
                                    <div class="col-md-7">
                                        <a class="text-break line-clamp font-weight-medium" :href="this.details.insystem.data.seller_url" target="_blank">
                                            <b>{{ details.insystem.data.seller_url }}</b>
                                        </a>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center" v-if="access() == 7">
                        <ar-button class="pt-2 pb-2 mr-1 w-100" @click="reassign.status = !reassign.status">
                            <i class="mr-1 fas fa-sync"/>
                            Reassign
                        </ar-button>
                        <div class="position-relative w-100">
                            <ar-button class="pt-2 pb-2 ml-1 w-100 btn-invalid" id="remove-cc-listing" @click="deleteListing.showConfirm = true">
                                <i class="mr-1 fas fa-trash-alt"/>
                                Remove
                            </ar-button>
                            <div class="remove-confirm pl-4 pr-4 pt-3 pb-3 position-absolute" :class="{'d-none': !deleteListing.showConfirm}">
                                <p class="font-weight-bold mb-0">Confirm to remove this listing</p>
                                <p>Do you wish to continue?</p>
                                <ar-button @click="removeListing()" :disabled="deleteListing.status" class="mr-2">Confirm</ar-button>
                                <ar-button @click="deleteListing.showConfirm = false" outlined>
                                    Cancel
                                </ar-button>
                            </div>
                        </div>
                    </div>
                    <ar-button 
                        class="mt-3 float-right" 
                        icon="fas fa-check" 
                        v-if="canEdit"
                        :disabled="details.status == 0 || (details.status == 3 && !details.insystem.value)"
                        @click="update()"
                    >
                        Save Changes
                    </ar-button>
                </div>
                <div class="col-md-6 pl-4 mt-2">
                    <template v-if="isReassign">
                        <div class="animated fadeIn">
                            <h5 class="d-inline-block ml-1 mt-3 align-middle">
                                Reassign Data
                                <span class="d-block font-weight-normal">Please select the researcher/user and status you want to reassign this data to.</span>
                            </h5>
                            <div class="mb-3">
                                <ar-select type="search" placeholder="Select Users" label="Users" :val="reassign.user.selected" v-model="reassign.user.selected" :options="reassign.user.data">Select Researcher</ar-select>
                            </div>
                            <div class="mb-4">
                                <ar-select type="search" placeholder="Select Status" label="Status" :val="reassign.qflag.selected" v-model.number="reassign.qflag.selected" :options="reassign.qflag.data"/>
                            </div>
                            <ar-button class="pt-2 pb-2 mr-1" :disabled="!this.reassign.user.selected || !this.reassign.qflag.selected" @click="reassignListing()">
                                Continue <i class="fas fa-arrow-right" />
                            </ar-button>
                        </div>
                    </template>
                    <template v-else-if="details.commentTab">
                        <div class="animated fadeIn">
                            <ar-textfield 
                                placeholder         ="Make a comment here"
                                a_type              ="textarea"
                                :a_style            ="`resize: none; height:38px; ${details.comment.focused ? 'height: 100px;': ''}`"
                                v-model.trim        ="details.comment.value"
                                @focus              ="updateFocused()"
                                @blur               ="updateFocused()"
                            />
                            <div class="btn-con text-right">
                                <ar-button 
                                    outlined 
                                    style="color: #202229; border-color: #202229;"
                                    :disabled="!details.comment.value"
                                    @click="clearComment()"
                                >Cancel</ar-button>
                                <ar-button class="ml-2" :disabled="!details.comment.value" @click="addComment()">
                                    Submit
                                </ar-button>
                            </div>
                            <hr/>
                            <div class="comments-con pr-2" :class="{'lessen': details.comment.focused}">
                                <template v-if="details.comment.data.length">
                                    <div 
                                        :class="{'active-delete': details.comment.deleteIndex == index}"
                                        v-for="(comment, index) in details.comment.data"
                                        :id="`comment--${index}`"
                                        :key="`comment--${index}`"
                                    >
                                        <div class="position-relative comment-con">
                                            <img class="icon" :src="comment.userimg" alt="icon">
                                            <div class="comment-details-con">
                                                <div class="mb-1">
                                                    <a href="javascript:void(0)" title="Delete comment" class="delete-comment-btn d-inline-block" v-if="comment.userid == currentUser.id" @click="details.comment.deleteIndex = index">
                                                        <img src="/static/svg/Status_Delete.svg" alt="delete">
                                                    </a>
                                                    <h5 class="d-inline-block mb-0">{{ comment.user }}</h5>
                                                    <span class="text-muted text-sm d-block font-italic">{{ comment.datetime }}</span>
                                                </div>
                                                <div class="comment-details-con-con">
                                                    <p class="mb-2" v-html="comment.value"></p>
                                                </div>
                                            </div>
                                        </div>
                                        <hr class="m-0" v-if="details.comment.deleteIndex == index"/>
                                        <template v-if="details.comment.deleteIndex == index">
                                            <div class="d-flex animated fadeIn justify-content-between align-items-center p-2">
                                                <p class="text-danger remove-confirm-text">Please confirm to remove this comment.</p>
                                                <div>
                                                    <a title="Cancel" class="remove-confirm-btn position-relative" @click="details.comment.deleteIndex = -1">
                                                        <img src="/static/svg/Remove_Cancel.svg" alt="icon">
                                                    </a>
                                                    <a title="Confirm" class="remove-confirm-btn position-relative ml-2" @click="removeComment(comment, index)">
                                                        <img src="/static/svg/Remove_Confirm.svg" alt="icon">
                                                    </a>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="text-center">
                                        <img src="/static/svg/counterfeit/No_comment.svg" alt="no comment">
                                    </div>
                                </template>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="details.invalidNote">
                        <div class="animated fadeIn faster invalid-note-con">
                            <div class="invalid-note-header">
                                <div>
                                    <img src="/static/svg/new-guidelines/additional-instructions.svg">
                                </div>
                                <div>
                                    <span class="invalid-note-label">
                                         Invalid Notes 
                                    </span>
                                    <h5 class="invalid-note-team"> NAS Operations Team </h5>
                                </div>
                            </div>
                            <div class="invalid-note-body">
                                <div class="invalid-note-important-note">
                                    <i class="fas fa-thumbtack"></i>
                                    <span class="invalid-note-label"> Invalidated due to the following reasons </span>
                                </div>
                                <div class="invalid-note-reasons">
                                    <div v-for="(reason, index) in computedInvalidNote" :key="index" class="reasons-note">
                                        <span v-show="reason.main" class="reason-main-note"> - {{ reason.main }} </span>
                                        <span v-show="reason.other" class="reason-sub-other"><i> {{ reason.other }} </i></span>
                                        <span v-show="reason.sub" class="reason-sub-other"><i> {{ reason.sub }} </i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="animated fadeIn border rounded">
                            <h4 class="mb-4 ml-4 mt-3"><small class="font-weight-medium">Status History</small></h4>
                            <div class="counterfeit-checker-history-con">
                                <template v-if="generalHistory.length > 0 && !details.invalidHistory">
                                    <ar-list 
                                        type="history"
                                        v-for="(history, index) in generalHistory"
                                        :key="index"
                                        :subject="history.name"
                                        :details="history.text"
                                        :date="history.created_at"
                                        :field="history.field"
                                        :action="history.action"
                                    ></ar-list>
                                </template>
                                <template v-else-if="generalHistory.length > 0 && details.invalidHistory">
                                    <ar-list
                                        v-for="(invalidNote, index) in computedInvalidNote"
                                        :key="index"
                                        type="history"
                                        subject="NAP Operations Team"
                                        :details="statusHistory(invalidNote)"
                                    ></ar-list>
                                </template>
                                <template v-else>
                                    <div class="empty-history-state">
                                        <img src="/static/svg/Status_Photo.svg" alt="nostatus">
                                    </div>
                                </template>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </ar-dialog>
        <ar-dialog
            id="edit-status-modal"
            large
            :xLarge="!!add.response.length"
            custom_width="100%"
            :has_button="false"
            :has_footer="false"
            data-backdrop="static"
        >
        <div style="display: flex; column-gap: 20px;">
            <div v-if="monitoringClicked == true" class="edit-listing-container">
                <div class="reasons-description" style="margin-top: 30px;">
                    <div class="import-listing-header">
                        <h5 class="d-inline-block align-middle">
                            For Monitoring
                        </h5>
                        <h3 class="import-listing-description">
                            A Selection of predefined reasons are available below. 
                            Select Others to provide a customer reason. Confirm by clicking on the 'Confirm' button.
                        </h3>
                    </div>
                    <ar-reason-selection v-if="monitoringClicked == true || rejectedClicked == true" :reasons="predefinedReasons" v-model="selected_reasons" />
                </div>
            </div>
            <div v-else-if="rejectedClicked == true" class="edit-listing-container">
                <div class="reasons-description" style="margin-top: 30px;">
                    <div class="import-listing-header">
                        <h5 class="d-inline-block align-middle">
                            Reject Data
                        </h5>
                        <h3 class="import-listing-description">
                            A Selection of predefined reasons are available below. 
                            Select Others to provide a customer reason. Confirm by clicking on the 'Confirm' button.
                        </h3>
                    </div>
                    <ar-reason-selection v-if="monitoringClicked == true || rejectedClicked == true" :reasons="predefinedReasons" />
                </div>
            </div>
            <div v-else class="edit-listing-container">
                <div class="import-listing-header">
                    <h5 class="d-inline-block align-middle">
                        Import Data Summary
                    </h5>
                </div>
                <div class="import-listing-details">
                    <div class="details-labels">
                        <span class="import-status" :class="{'not_found': !selectedImportListing.success}" style="margin-right: 10px"> 
                            {{['Not Imported', 'Imported'][+selectedImportListing.success]}} 
                        </span>
                        <span
                            style="margin-right: 10px"
                            class="selectedImportListing"
                            :class="{
                                    'monitoring' : (selectedImportListing.status == 'Monitoring' || selectedImportListing?.listing?.status == 'Monitoring'),
                                    'forReview' : (selectedImportListing.status == 'Qualified' || selectedImportListing?.listing?.status == 'Qualified'),
                                    'rejected' : (selectedImportListing.status == 'Rejected'|| selectedImportListing?.listing?.status == 'Rejected'),
                                    }"
                        >
                            {{
                                selectedImportListing.status ? selectedImportListing.status == "Qualified" ? "For Review" : 
                                selectedImportListing.status == "Researched" ? "For Quality Checking" : 
                                selectedImportListing?.listing?.status == "Qualified" ? "For Review" : selectedImportListing.status : 
                                selectedImportListing?.listing?.status == "Researched" ? "For Quality Checking" : 
                                selectedImportListing?.listing?.status
                            }}
                            <!-- {{ 
                                selectedImportListing.status == "Qualified" ? "For Review" : 
                                selectedImportListing.status == "Researched" ? "For Quality Checking" : 
                                selectedImportListing.status 
                            }} -->
                        </span>
                        <span class="listing-details-message"> {{ selectedImportListing.message }}  </span>
                    </div>
                    <div class="form-group w-100 mb-0" style="display: flex; flex-direction: column; row-gap: 10px;">
                        <div class="listing-label">
                            <img src="/static/svg/ListingInfo_ItemType.svg" alt="" class="label-icon" style="margin-left: 3px; height: 13px; width: 13px;">
                            <label class="col-form-label">PRODUCT URL</label>
                        </div>
                        <div class="listing-url-container">
                            <a
                                :href="selectedImportListing.product_url ? selectedImportListing.product_url : selectedImportListing?.listing?.url"
                                target="_blank" class="listing-url"
                            >
                                {{selectedImportListing.product_url ? selectedImportListing.product_url : selectedImportListing?.listing?.url}}
                            </a>
                        </div>
                        <div class="listing-label">
                            <img src="/static/svg/ListingInfo_SellerURL.svg" alt="" class="label-icon" style="margin-left: 3px; height: 13px; width: 13px;">
                            <label class="col-form-label">STORE URL</label>
                        </div>
                        <div class="listing-url-container">
                            <a v-if="selectedImportListing.store_url !== ''" :href="selectedImportListing.store_url" target="_blank" class="listing-url">
                                {{selectedImportListing.store_url}}
                            </a>
                            <a v-else :href="selectedImportListing.store_url" target="_blank">
                                <span style="font-size: 15px; color: #000000;">No Value</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="status-change-selection">
                <div style="color: #141517;">
                    <h5 class="mb-4" style="margin-left: -100px;"> Select a Status Change </h5>
                </div>
                <div class="status-change-buttons">
                    <button 
                        v-if="selectedImportListing.status !== 'Monitoring' && selectedImportListing?.listing?.status !== 'Monitoring'"
                        type="button"
                        class="button-monitoring"
                        :class="{'monitoringClicked' : monitoringClicked == true}"
                        @click="monitoringClicked = !monitoringClicked; 
                                acceptedClicked = false; rejectedClicked = false; qflagId = 31;
                                getPredefinedReasons()"
                    >
                        <div class="monitoring-button-icon">
                        </div>
                        <div class="status-button-details">
                            <span class="monitoring-detail-header"> Mark as Monitoring </span>
                            <span class="monitoring-detail-description">
                                The client needs an in-depth analysis to approve or reject the data
                            </span>
                        </div>
                    </button>
                    <button
                        v-if="!['Accepted', 'Recycled'].includes(selectedImportListing.status ?? selectedImportListing?.listing?.status)"
                        type="button"
                        class="button-accept"
                        :class="{'acceptedClicked' : acceptedClicked == true}"
                        @click="monitoringClicked = false; acceptedClicked = !acceptedClicked; 
                                rejectedClicked = false; getPredefinedReasons(); qflagId = 32"
                    >
                        <div class="accept-button-icon">
                        </div>
                        <div class="status-button-details">
                            <span class="accept-detail-header"> Accept Data </span>
                            <span class="accept-detail-description">
                                Data is confirmed by the client that it is indeed a counterfeit and approved to the include in the filing
                            </span>
                        </div>
                    </button>
                    <button
                        v-if="selectedImportListing.status !== 'Rejected' && selectedImportListing?.listing?.status !== 'Rejected'"
                        type="button"
                        class="button-reject"
                        :class="{'rejectedClicked' : rejectedClicked == true}"
                        @click="monitoringClicked = false; acceptedClicked = false; 
                                rejectedClicked = !rejectedClicked; qflagId = 33; getPredefinedReasons()"
                    >
                        <div class="reject-button-icon">
                        </div>
                        <div class="status-button-details">
                            <span class="reject-detail-header"> Reject Data </span>
                            <span class="reject-detail-description">
                                Data is confirmed by the client or law firm that is not suitable for filing
                            </span>
                        </div>
                    </button>
                </div>
                <div class="status-change-confirm-btn">
                    <ar-button
                        class="change-status-btn"
                        :disabled="($store.state.checked_reasons.length == 0) && acceptedClicked == false"
                        @click="changeDataCheckerStatus()"
                    >
                        Confirm This Action
                    </ar-button>
                </div>
            </div>
        </div>
        <div class="edit-listing-button">
            <ar-button 
                outlined 
                data-dismiss="modal"
                style="color: #202229; border-color: #202229;"
                @click="showListingSummary(); selectedImportListing = [];
                        acceptedClicked = false; monitoringClicked = false;
                        rejectedClicked = false; selectedImportListingId = [];
                        isImportSheetOption = false"
            >
                Cancel
            </ar-button>
            <ar-button
                class="ml-2"
                data-dismiss="modal"
                @click="openAddModal(); acceptedClicked = false; monitoringClicked = false;
                        rejectedClicked = false;"
            >
                Add More
            </ar-button>
        </div>
        </ar-dialog>
        <ar-dialog id="download-confirmation">
            <div>
                <div class="d-flex flex-column align-items-center justify-content-start" style="gap: 5px;">
                    <p class="m-0" style="color: #79BD9A;font-size: 24px;font-weight: bold;letter-spacing: 0.36px;">Download Listing!</p>
                    <p class="m-0" style="font-size: 16px;width: 100%;">Do you want to download the <b>({{ selected_listings.length }}) selected listing?</b></p>
                </div>
            </div>
            <template v-slot:buttons>
                <ar-button
                    a_class="mr-2"
                    data-dismiss="modal"
                    outlined
                >
                    Cancel
                </ar-button>
                <form method="post" :action="`${env}/api/v2/client_listings/export?token=${token_noBearer}`" target="_blank">
                    <!-- <template></template> -->
                    <input v-for="(cl, index) in selected_listings" :key="index" type="hidden" name="id[]" :value="cl">
                    <ar-button type="submit" @click="closeModal()">
                        Proceed
                    </ar-button>
                </form>
            </template>
        </ar-dialog>

        <main class="main-con">
            <template v-if="filtered_listings.result.length">
                <ar-list-item
                    v-for="listing in filtered_listings.result"
                    :has_checkbox="true"
                    :id="`client-listing--${listing.id}`"
                    :key="listing.key_id"
                    :type="toList(listing).type"
                    :label="toList(listing).label"
                    :datetime="toList(listing).datetime"
                    :status="(listing.status == 'Recycled' && access() == 6 ? 'Accepted' : 
                            listing.status == 'Invalid' && listing?.invalidReasons?.length ? listing?.invalidReasons[0]?.main : listing.status)"
                    @click="openDetails(listing); setInvalidNotes()"
                    :clid="listing.id"
                    @change="addOrRemove(selected_listings, listing.id)"
                />
            </template>
            <div v-else class="empty-listing-con">
                <img src="/static/svg/NoListings_Photo.svg" alt="No listings">
            </div>
        </main>
         <footer class="pagination-footer">
            <div class="d-flex justify-content-between">
                <ar-select 
                    v-model="page_size"
                    :options="page_size_options"
                    :first_element_as_default="true"
                    style="z-index: 1"
                    @input="current_page = 1">
                </ar-select>
                <ar-pagination
                    :page_no="current_page"
                    :last_page="last_page"
                    @prev-click="current_page--"
                    @next-click="current_page++"
                    @first-click="current_page = 1"
                    @last-click="current_page = last_page"
                    @enter="gotoPage"
                    @keypress.native="isNumber"
                />
            </div>
        </footer>
    </div>
</template>

<script>
    import GlobalComponents from '@/globalComponents.js'
    import axios from '../../../axiosMime'
    import qs from 'qs';
    import moment from 'moment';
    import {mapState, mapActions} from 'vuex';
    import { addOrRemove } from '@/utils/array'
    export default {
        name: "ar-campaigncounterfeitchecker",
        components: {
            ...GlobalComponents,
            ArReasonSelection: () => import('@/components/pages/verification/listing/ReasonsSelection'),
            ArTitlebar: () => import('../../layouts/TitleBar'),
            ArButton: () => import('../../layouts/Button'),
            // ArChip: () => import('../../layouts/Chip'),
            ArListItem: () => import('../../layouts/ListItems'),
            ArTextfield: () => import('../../layouts/Textfield'),
            ArDialog: () => import('../../layouts/Dialog'),
            ArSelect: () => import('../../layouts/Select'),
            ArPagination: () => import('../../layouts/Pagination'),
            ArList: () => import('../../layouts/List'),
            ArRadio: () => import('../../layouts/RadioButton'),
            // ArCheckbox: () => import('../../layouts/Checkboxes')
        },
        data() {
            return {

                dates : [],
                filters: {
                    search: {
                        value: "",
                        valid: true
                    },
                    status: {
                        selected: 0,
                        data: []
                    },
                    order: {
                        selected: 1,
                        data: [{
                            label: "Date Added - Ascending",
                            value: 0
                        },
                        {
                            label: "Date Added - Descending",
                            value: 1
                        },
                        {
                            label: "URL - Ascending",
                            value: 2
                        },
                        {
                            label: "URL - Descending",
                            value: 3
                        },
                        {
                            label: "Status - Ascending",
                            value: 4
                        },
                        {
                            label: "Status - Descending",
                            value: 5
                        }]
                    },
                    claimtype: {
                        selected: 0,
                        data: []
                    },
                    daterange: {
                        selected: {
                            start: null,
                            end: null
                        },
                        start: moment().format('YYYY-MM-DD'),
                        end: moment().format('YYYY-MM-DD'),
                        max: moment().format('YYYY-MM-DD')
                    }
                },
                campaigns: [],
                notification_loading : false,
                visible : 0,
                visible_total: 0,
                current_page: 1,
                page_size: 12,
                campaign_data: { img: '/static/svg/Campaign Default Photo.svg', name: "" },
                listings: [],
                add: {
                    adding: false,
                    current: 0,
                    matches_found: {
                        data: [],
                        options: [{
                            label: "Use Product",
                            value: 1
                        },
                        {
                            label: "Skip",
                            value: 2
                        }],
                        bulk_action: [],
                        is_bulk_action: false
                    },
                    manual: {
                        data: [],
                        duplicates: [],
                        checking: false,
                        invalid_url: []
                    },
                    import: {
                        file_name: "",
                        file_data: {},
                        error: "",
                        start: 2,
                        generating: false,
                        duplicates: [],
                        checking: false,
                        withHeaders: [1],
                        multiple_sheets_detected: false,
                        uploading: false,
                        isImport: false,
                        sheets: {
                            selected: "",
                            raw: {},
                            options: [],
                            data: []
                        },
                        columns: {
                            data: [],
                            selected: { productUrl: "", storeUrl: "" }
                        },
                        options: [{
                            label: "Store URL",
                            value: 1
                        },
                        {
                            label: "Product URL",
                            value: 2
                        }],
                        columns_selection: false,
                        invalid_url: []
                    },
                    response: []
                },
                details: {
                    comment: {
                        data: [],
                        focused: false,
                        deleteIndex: -1,
                        value: "",
                        accessRoles: {
                            1: "Researcher",
                            3: "Lawyer",
                            5: "Campaign Lead",
                            6: "Client",
                            7: "Super Admin"
                        },
                        recursive: false,
                        cancelToken: axios.CancelToken.source()
                    },
                    commentTab: true,
                    invalidNote: false,
                    invalidHistory: false,
                    invalidReasons: [],
                    id: 0,
                    client: "",
                    url: "",
                    claimtype: 0,
                    status: 0,
                    datetime: "",
                    copy: {
                        status: 0
                    },
                    insystem: {
                        data: {
                            listing_status: "",
                            seller_name: "",
                            listing_id: 0,
                            listing_url: "",
                            listing_title: "",
                            preview: "",
                            platform: "",
                            platform_id: 0,
                        },
                        value: "",
                        key: this.getRandomKey()
                    },
                    employee: {
                        id: 0,
                        name: ""
                    },
                },
                page_size_options: [
                    {
                        label: 'Show 12 items',
                        value: 12
                    },
                    {
                        label: 'Show 30 items',
                        value: 30
                    },
                    {
                        label: 'Show 60 items',
                        value: 60
                    },
                    {
                        label: 'Show 120 items',
                        value: 120
                    }
                ],
                generalHistory: [],
                currentID: 0,
                reassign: {
                    status: false,
                    user: {
                        data: [],
                        selected: 0
                    },
                    qflag: {
                        data: [],
                        selected: 2
                    },
                    loading: false
                },
                deleteListing: {
                    status: false,
                    showConfirm: false
                },
                monitoringClicked: false,
                acceptedClicked: false,
                rejectedClicked: false,
                selectedImportListing: [],
                selectedImportListingId: [],
                qflagId : 0,
                predefinedReasons: [],
                selected_reasons: [],
                selected_reasons2:[],
                isImportSheetOption: false,
                lfcInvalidNote: [],

                select_all_text: "Select",
                is_select_all: true,
                selected_listings: [],
                addOrRemove,
            }
        },
        created() {
            moment.updateLocale('en', {
                calendar : {
                    lastDay : '[Yesterday] - ',
                    sameDay : '[Today] - ',
                    lastWeek : '[]',
                    sameElse : '[]'
                }
            });
        },
        async mounted() {
            this.initializeSelectedCampaign();
            this.initializeModalCloseListener();
            await this.getClaimTypes();
            this.getListings();
            this.getStatuses();
            this.getResearcher();
        },
        computed: {
            ...mapState(["checked_reasons"]),
            last_page() {
                return Math.ceil(this.visible_total / parseInt(this.page_size));
            },
            from_page_index() {
                return (this.current_page - 1) * parseInt(this.page_size);
            },
            to_page_index() {
                return (this.from_page_index + parseInt(this.page_size));
            },
            from_page_count() {
                return this.filtered_listings.visible_total ? (this.current_page * parseInt(this.page_size)) - parseInt(this.page_size) + 1 : 0;
            },
            to_page_count() {
                return (this.current_page * parseInt(this.page_size)) - (parseInt(this.page_size) - this.visible);
            },
            filtered_listings() {
                let listings = this.listings;
                if (this.filters.claimtype.selected > 0) {
                    listings = listings.filter(l => l.listing_details?.claim_type_id == this.filters.claimtype.selected)
                } else if (this.filters.claimtype.selected == -1) {
                    listings = listings.filter(l => l.listing_details?.claim_type_id == null || l.listing_details?.claim_type_id == '')
                }

                if (this.filters.status.selected) {
                    if(isNaN(this.filters.status.selected)){
                        listings = listings.filter(l => l.invalidReasons != null).filter(l => l.invalidReasons[0]?.main == this.filters.status.selected)
                    }else{
                        if (this.filters.status.selected == 32 && this.access() == 6) {
                            listings = listings.filter(l => ['Recycled', 'Accepted'].includes(l.status))
                        }
                        else {
                            listings = listings.filter(l => l.status == this.detailsStatusLabel(this.filters.status.selected))
                        }
                    }
                }

                if (this.filters.daterange.selected.start && this.filters.daterange.selected.end) {
                    listings = listings.filter(l => moment(l.created_at, "YYYY-MM-DD").isBetween(this.filters.daterange.selected.start, this.filters.daterange.selected.end) 
                        || moment(l.created_at, "YYYY-MM-DD").isSame(this.filters.daterange.selected.start) 
                        || moment(l.created_at, "YYYY-MM-DD").isSame(this.filters.daterange.selected.end))
                }

                if (this.filters.search.value) {
                    let search = this.filters.search.value.toLowerCase();
                    listings = listings.filter(l => l.listing_url?.toLowerCase()?.includes(search) || l.seller_url?.toLowerCase()?.includes(search));
                }

                let result = listings.slice(this.from_page_index, this.to_page_index);
                let visible_total = listings.length;
                let visible = result.length;
                return { result, visible_total, visible };
            },
            currentClaimtype() {
                return (this.filters.claimtype.data.filter(type => type.value == this.details.claimtype)[0] || {}).label
            },
            currentStatus() {
                let status = (this.statusOptions.find(stats => stats.value == this.details.status) ?? {}).label ?? ""
                let state = "pending"

                switch (status) {
                    case "Work In Progress":
                    case "Monitoring":
                        state = "ongoing"
                    break;
                    case "Rejected":
                    case "Invalid":
                        state = "invalid"
                    break;
                    case "Unchecked":
                        state = "unchecked"
                    break;
                    default:
                        state = "done"
                    break;
                }

                // switch (status) {
                //     case "Done":
                //         state = "done"
                //         break;
                //     case "Checking":
                //         state = "ongoing"
                //         break;
                //     case "In the System":
                //         state = "invalid"
                //         break;
                //     default:
                //         state = "pending"
                //         break;
                // }

                return { label: status, state }
            },
            showLIDField() {
                // return this.details.status == 3 && (![3,6].includes(this.access()) || this.currentUser.id == this.details.employee.id || this.details.employee.id == 0);
                return false
            },
            showInSystemDetails() {
                // return this.details.status == 3 && this.details.insystem.data.listing_id && this.details.insystem.data.seller_name && this.details.insystem.data.listing_status;
                return true
            },
            currentUser() {
                let data = JSON.parse(localStorage.getItem('lawfirm'))
                return { id: data.id, name: `${(data.first_name || "").trim()} ${(data.last_name || "").trim()}` }
            },
            selectedFilter() {
                let get = (key) => this.filters[key];
                let selected = {
                    claimtype: get("claimtype").selected,
                    status: get("status").selected,
                    order: get("order").selected
                }
                let options = {
                    claimtype: get("claimtype").data,
                    status: get("status").data,
                    order: get("order").data
                }

                let claimtype =  selected.claimtype > 0
                                    ? options.claimtype.filter(data => data.value == selected.claimtype)[0].label 
                                    : selected.claimtype == -1 
                                        ? "No Claim Type"
                                        : "All Claim Types"
                let status = isNaN(selected.status) ? selected.status : selected.status ? options.status.filter(data => data.value == selected.status)[0].label || "" : "All Status"
                let order
                
                switch (selected.order) {
                    case 1: 
                        order = "Date Added - Descending"
                        break;
                    case 2: 
                        order = "URL - Ascending"
                        break;
                    case 3:
                        order = "URL - Descending"
                        break;
                    case 4: 
                        order = "Status - Ascending"
                        break;
                    case 5:
                        order = "Status - Descending"
                        break;
                    default:
                        order = "Date Added - Ascending"
                        break;
                }

                return { claimtype, status, order }
            },
            listingOwnerName() {
                // return ![3,6].includes(this.access()) ? this.details.client : "Counterfeit Checker";

                return !this.isFeatureRestricted('cm-text-data-checker-owner') ? this.details.client : 'Counterfeit Checker';
            },
            hasEmptyInvalidURL() {
                return !!(this.checkURL.hasEmpty.length || this.checkURL.hasDuplicate.length || this.checkURL.isNotValid.length)
            },
            checkURL() {
                let hasEmpty = []
                let hasDuplicate = []
                let isNotValid = []
                for (let index = 0; index < this.add.manual.data.length; index++) {
                    let data = this.add.manual.data[index];

                    if (!data.storeUrl && !data.productUrl)
                        hasEmpty.push(index)

                    if (this.add.manual.data.filter(item => item.storeUrl == data.storeUrl && data.storeUrl).length > 1) {
                        hasDuplicate.push(JSON.stringify({ index, inner: 1 }))
                    }

                    if (this.add.manual.data.filter(item => item.productUrl == data.productUrl && data.productUrl).length > 1) {
                        hasDuplicate.push(JSON.stringify({ index, inner: 2 }))
                    }

                    if (!this.isValidURL(data.storeUrl) && data.storeUrl) {
                        isNotValid.push(JSON.stringify({ index, inner: 1 }))
                    }

                    if (!this.isValidURL(data.productUrl) && data.productUrl) {
                        isNotValid.push(JSON.stringify({ index, inner: 2 }))
                    }
                }

                return { hasEmpty, hasDuplicate, isNotValid }
            },
            selectedDaterange() {
                return this.filters.daterange.selected.start && this.filters.daterange.selected.end
                    ? `Current: ${this.filters.daterange.selected.start} - ${this.filters.daterange.selected.end}`
                    : this.$t('Date Picker')
            },
            showClearSelectedDaterangeButton() {
                return this.filters.daterange.selected.start && this.filters.daterange.selected.end
            },
            defaultFilters() {
                return this.filters.status.selected == 0 && this.filters.order.selected == 1 && this.filters.claimtype.selected == 0 && this.filters.daterange.selected.start == null && this.filters.daterange.selected.end == null
            },
            addModalSubtitle() {
                return this.add.manual.duplicates.length || this.add.import.duplicates.length || this.add.manual.invalid_url.length || this.add.import.invalid_url.length
                    ? 'Below are urls that are already in entered in the counterfeit checker so it is considered as a duplicate.'
                    : 'Please fill in the details to complete. For multiple records click the <b class="font-weight-medium text-primary">Add more Data</b> to input additional data.'
            },
            addInvalidURLModalSubtitle() {
                return this.add.manual.duplicates.length || this.add.import.duplicates.length || this.add.manual.invalid_url.length || this.add.import.invalid_url.length
                    ? 'Below are urls that are considered as an invalid urls.'
                    : 'Please fill in the details to complete. For multiple records click the <b class="font-weight-medium text-primary">Add more Data</b> to input additional data.'
            },
            canEdit() {
                // return (![3,6].includes(this.access()) && this.currentUser.id == this.details.employee.id) || (![3,6].includes(this.access()) && this.details.employee.id == 0)
                return false
            },
            hasNoActionSelected() {
                return this.add.matches_found.data.some(item => item.action == 0)
            },
            hasAssociatedColumnSelected() {
                return this.add.import.columns.data.some(data => data.associated == 1) || this.add.import.columns.data.some(data => data.associated == 2)
            },
            sheetsDetectedLabel() {
                return this.add.import.sheets.options.length == 1 ? 'The system found a sheet/tab on the uploaded file' : 'The system detected multiple sheets/tabs'
            },
            statusOptions() {
                return this.listings.map(l => ({ label: l.status, value: !["Checking", "Unchecked", "In the System", "Done"].includes(l.status) ? this.$parent.getQFlagId(l.status) : -999 }))
            },
            isReassign() {
                return this.reassign.status
            },
            isLoggedIn() {
                return localStorage.getItem('lawfirm') !== null
            },
            computedInvalidNote() {
                return this.lfcInvalidNote
            },
            filteredInvalidReasons() {
                const reasons = this.listings.filter(reason => reason.invalidReasons)
                return reasons
            },
            mappedInvalidReasons() {
                const reasons = this.filteredInvalidReasons?.map(reason => reason?.invalidReasons)
                let mapped = new Set(reasons.map(item => item[0]?.main));
                return Array.from(mapped)
            },
            filtered_status() {
                let status = this.filters.status.data
                let reasons = this.mappedInvalidReasons?.map(reason => ({
                    label: 'Invalid: ' + reason, value: reason
                }))
                if (this.access() == 6) {
                    status.filter( s => !['archived', 'Recycled'].includes(s.label))
                }
                else {
                    status.filter( s => s.label !== 'archived')
                }
                return status.concat(reasons)
            },
            isListingSummaryDismissable() {
                return this.add.response.filter(clientListing =>
                    ["Qualified", "Monitoring", "Rejected"].includes(clientListing.listing?.status)
                ).length === 0;
            },
            token_noBearer() {
                let token = this.token.headers.Authorization.split(" ")[1]
                return token
            },
            token() {
                return this.$session.get('lawfirm_headers')
            },
            env() {
                return process.env.VUE_APP_URL
            },
        },
        methods: {
            ...mapActions(['setRelatedCampaignDuplicates', 'setRelatedCampaignDuplicateBypass', 'setRelatedCampaignDuplicateContinueFunction']),
            itemStatus(item) {
                return ( item?.listing?.status != null ? item.listing.status : ((item || {}).listing || {}).status == 'Researched' ? 'For Quality Checking': ((item || {}).listing || {}).status == 'Qualified' ? 'For Review' : ((item || {}).listing || {}).status
                        || (item || {}).status == 'Researched' ? 'For Quality Checking' : (item || {}).status == 'Qualified' ? 'For Review' : (item || {}).status || "No Value")
            },
            itemStatusClass(item){
                let colors = {
                    Rejected: 'not_found',
                    Invalid: 'not_found',
                    Monitoring: 'wip',
                    "Work In Progress": "wip",
                    "For Quality Checking": 'ongoing',
                    Accepted: 'ongoing',
                    Filed: 'ongoing',
                    Checking: 'ongoing',
                    Unchecked: 'unchecked',
                    "For Review": 'ongoing',
                }
                if (this.access() == 6) {
                    colors = {
                        Rejected: 'not_found',
                        Invalid: 'not_found',
                        Monitoring: 'wip',
                        "Work In Progress": "wip",
                        "For Quality Checking": 'ongoing',
                        Accepted: 'ongoing',
                        Recycled: 'ongoing',
                        Filed: 'ongoing',
                        Checking: 'ongoing',
                        Unchecked: 'unchecked',
                        "For Review": 'ongoing',
                    }
                }
                let itemStatus = this.itemStatus(item)
                return colors[itemStatus] == null ? 'others' : colors[itemStatus]
            },
            getImportedListingID(){
                this.selectedImportListingId = this.getListingId(this.selectedImportListing);
                return this.selectedImportListingId
            },
            getListingId(listing) {
                if (listing.listing) {
                    return listing.listing.id
                }
                else {
                    return listing.listing_id
                }
            },
            changeListingStatus(listing, qflagId) {
                this.selectedImportListing = listing;
                this.getImportedListingID();
                this.qflagId = qflagId;
                this.monitoringClicked = qflagId === 31;
                this.acceptedClicked = qflagId === 32;
                this.rejectedClicked = qflagId === 33;
                this.getPredefinedReasons();
            },
            hideListingSummary() {
                $("#import-listing-modal").modal("hide");
            },
            hideEditStatusModal() {
                $("#edit-status-modal").modal("hide");
            },
            getPreview() {
                return `${process.env.VUE_APP_URL}/files/${this.details.insystem.data.preview}`
            },
            reassignListing() {
                let header = JSON.parse(JSON.parse(localStorage.getItem("lawfirm")).headers)
                header.headers["Campaign-id"] = this.$route.params.campaign_id
                this.$http.patch(`client_listings/reassign/${this.details.id}/user/${this.reassign.user.selected}/status/${this.reassign.qflag.selected}`, {}, header)
                    .then(({ data }) => {
                        let updated = data.data[0]
                        this.listings = this.listings.map(p => (p.id === updated.id ? updated : p))

                        this.$parent.showSnackbar("Successfully reassigned listing",false,"success","fas fa-check-circle")
                        
                        this.getHistory()
                        this.getListings(false)
                        this.closeModal()
                    })
                    .catch(err=>{alert(err.response.data.meta.message+"Please contact system admin.")})
            },
            closeModal() {
                $('.modal').modal('hide')
            },
            initializeSelectedCampaign() {
                let siblings_component = this.$parent.$children
                let campaign_name = null
                let campaign_display_interval = setInterval(() => {
                    if (this.campaigns.length > 0) {
                        if ((campaign_name = this.campaigns.filter(c => c.id == this.$route.params.campaign_id)) != null) {
                            campaign_name = ((campaign_name[0] || {}).campaign || "")
                            this.campaign_data = { img: this.$parent.getImage('campaigns', campaign_name), name: campaign_name }
                            clearInterval(campaign_display_interval)
                        }
                    } else {
                        setTimeout(() => {
                            for (let cindex = 0; cindex < siblings_component.length; cindex++) {
                                let c = siblings_component[cindex]
                                if (c.$vnode.componentOptions.tag == 'ar-sidebar') {
                                    this.campaigns = c.campaigns
                                    for (let index = 0; index < this.campaigns.length; index++) {
                                        let campaign = this.campaigns[index]
                                        if (campaign.id == this.$route.params.campaign_id) {
                                            campaign_name = campaign.campaign
                                            this.campaign_data = { img: this.$parent.getImage('campaigns', campaign_name), name: campaign_name }
                                            return
                                        }
                                    }
                                }
                            }
                        }, 500)
                    }
                }, 3500);
            },
            async getListings(refresh = true) {
                if (refresh)
                    this.$parent.showBackdrop();

                let headers = {headers: {...this.$session.get('lawfirm_headers').headers, Feature: 'counterfeit-checker'}};
                return await axios
                    .get(`${process.env.VUE_APP_URL}/client_listings/by/campaign/${this.$route.params.campaign_id}?page_size=1000000`, headers)
                    .then(response => {
                        this.listings = response.data.data.map(listing => ({
                            ...listing,
                            key_id: this.getRandomKey(),
                            status: ["New", "Work in Progress"].includes(listing.status)  ? "Work In Progress" : listing.status,
                            listing_details: {
                                ...listing.listing_details,
                                listing_status: ["New", "Work in Progress"].includes(listing.status) ? "Work In Progress" : listing.status
                            },
                        }));
                        this.sort();
                        this.$parent.hideBackdrop();

                        if (this.$route.query.id != '' && this.$route.query.id != null && !isNaN(this.$route.query.id)) {
                            this.openDetails(this.listings.filter(item => item.id == this.$route.query.id)[0])
                            this.$router.replace({'query.id': null})
                        }

                        this.filters.status.data = [...new Map(this.statusOptions.map(item => [item['label'], item])).values()]

                        return true;
                    })
                    .catch(ex => {
                        console.log(ex);
                        this.$parent.hideBackdrop();
                        // this.$parent.showSnackbar('Something went wrong. Please contact system admin', false, 'invalid', 'fas fa-times-circle');
                        this.$parent.showSnackbar(ex.response.data.meta.message, false, 'invalid', 'fas fa-times-circle');

                        return false;
                    });
            },
            async getClaimTypes() {
                await this.$http.get('claimtype?page_size=100', this.$session.get('lawfirm_headers'))
                    .then(response => {
                        this.filters.claimtype.data = response.data.data.map(type => ({ label: type.claim_type, value: type.id }))
                    })
            },
            applyFilter() {
                if (!(this.filters.daterange.start && this.filters.daterange.end)) {
                    this.$parent.showSnackbar("Please select a start date and end date!", false, "invalid", 'fas fa-times-circle');
                    return;
                }

                this.filters.daterange.selected.start = new Date(this.filters.daterange.start).toLocaleDateString();
                this.filters.daterange.selected.end = new Date(this.filters.daterange.end).toLocaleDateString();
            },
            toMomentCalendar(datetime) {
                return moment(datetime).calendar();
            },
            customDateFormat(date) {
                return moment(date).format('dddd, MMMM DD, YYYY')
            },
            customTimeFormat(datetime) {
                return moment(datetime).format('LT');
            },
            viewSourceFile(event) {
                if (event.target.files[0] != null) {
                    this.add.import.file_data = event.target.files[0]
                    this.add.import.file_name = this.add.import.file_data.name
                    this.importListings()
                }
            },
            openAddModal() {
                $('#import-listing-modal').modal({ backdrop: 'static', keyboard: false });
                this.add.import.withHeaders = [1];
                this.add.manual.checking = false;
                this.add.import.checking = false;
                this.add.adding = false;
                this.add.import.isImport = false;
                this.add.import.columns_selection = false;
                this.add.import.multiple_sheets_detected = false;
                this.add.current = 0;
                this.resetManual();
                this.resetImportResponse()
            },
            showListingSummary() {
                $('#import-listing-modal').modal({ backdrop: 'static', keyboard: false })
            },
            updateManualAdditionData(index = null) {
                if (index != null) {
                    this.add.manual.data.splice(index, 1)
                } else {
                    this.add.manual.data.push({ storeUrl: "", productUrl: "", claimtype: 0, valid: true })
                }
            },
            updateAddCurrent() {
                this.add.manual.checking = false
                this.add.import.start = 2;
                this.add.import.withHeaders = [1];
                this.add.current = !this.add.current
            },
            openDetails(details) {
                this.details.comment.cancelToken.cancel();
                this.details.comment.cancelToken = axios.CancelToken.source();

                this.details.commentTab = true;
                this.details.comment.domCounter = 0;
                this.details.comment.deleteIndex = -1;
                this.details.id = details.id;
                this.details.listing_url = details.listing_url;
                this.details.store_url = details.seller_url;
                this.details.claimtype = details.listing_details?.claim_type_id;
                this.details.status = this.detailsStatusID(details.status);
                this.details.copy.status = this.detailsStatusID(details.status);
                this.details.client = details.client_name || "";
                this.details.seller_url = details.seller_url;
                this.details.datetime = moment(details.created_at).format('DD MMMM YYYY');
                this.details.insystem.value = details.listing_id || "";
                this.details.listing_id = details.listing_id
                this.details.insystem.data.listing_status = details.listing_details?.listing_status;
                this.details.insystem.data.seller_name = details.listing_details?.seller_name;
                this.details.insystem.data.seller_url = details.listing_details?.seller_url;
                this.details.insystem.data.listing_id = details.listing_id;
                this.details.insystem.data.listing_url = details.listing_details?.listing_url;
                this.details.insystem.data.listing_title = details.listing_details?.title;
                this.details.insystem.data.preview = details.listing_details?.preview;
                this.details.insystem.data.platform_id = details.listing_details?.platform_id;
                this.details.insystem.data.platform = details.listing_details?.platform_name;
                this.details.comment.data = [];
                this.details.employee.id = details.employee_user_id
                this.details.employee.name = details.listing_details?.employee_name
                this.details.invalidReasons = details?.invalidReasons

                $('#listing-details-modal').modal('show');
                this.details.comment.recursive = true;
                this.reassign.status = false;
                this.reassign.user.selected = 0;
                this.reassign.qflag.selected = 0;
                this.getComments();
            },
            updateFocused() {
                this.details.comment.focused = this.details.comment.focused && this.details.comment.value ? this.details.comment.focused : !this.details.comment.focused
            },
            access() {
                return JSON.parse(localStorage.getItem('lawfirm')).access_level
            },
            isNumber(e) {
                var event = e || window.event;
                var keyCode = event.keyCode || event.which;            
                var key = String.fromCharCode(keyCode);
                var regex = /^[0-9\b]+$/;  

                if (key.length == 0) return;

                if(keyCode == 188 || keyCode == 190) return;
                else if(this.$route.params.case_id != '0' && keyCode == 45) return;
                else {
                    if (!regex.test(key)) {
                        event.returnValue = false;                
                        if (event.preventDefault) event.preventDefault();
                    }
                }    
            },
            isNumberOnly(e) {
                var event = e || window.event;
                var keyCode = event.keyCode || event.which;            
                var key = String.fromCharCode(keyCode);
                var regex = /^[0-9]*$/;  

                if (key.length == 0) return;

                console.log(keyCode)

                if(keyCode == 188 || keyCode == 190) return;
                else if(this.$route.params.case_id != '0' && this.$route.params.case_id != null && keyCode == 45) return;
                else {
                    if (!regex.test(key)) {
                        event.returnValue = false;                
                        if (event.preventDefault) event.preventDefault();
                    }
                }    
            },
            async getComments() {
                let headers = { cancelToken: this.details.comment.cancelToken.token, headers: {...this.$session.get('lawfirm_headers').headers, Feature: 'counterfeit-checker'}};
                let returnBool = false
                await axios
                    .get(`${process.env.VUE_APP_URL}/comments/by/client_listing/${this.details.id}`, headers)
                    .then(response => {
                        this.details.comment.data = response.data.data
                            .filter(d => this.access() == 6 ? d.user_id == this.currentUser.id : true)
                            .map(data => this.toComment(data));

                        // this.sort();
                        this.$parent.hideBackdrop();
                        returnBool = true;
                        this.lfcInvalidNote = response.data?.meta?.invalidReasons?.slice(0, 2)
                    })
                    .catch(ex => {
                        if (localStorage.getItem('lawfirm') != null) {
                            console.log(ex);
                            this.$parent.hideBackdrop();
                            // this.$parent.showSnackbar('Something went wrong. Please contact system admin', false, 'invalid', 'fas fa-times-circle');
                            this.$parent.showSnackbar(ex.response.data.meta.message, false, 'invalid', 'fas fa-times-circle');

                        }
                        returnBool = false;
                    })
                    .finally(() => {
                        if (this.details.comment.recursive) {
                            setTimeout(() => {
                                this.getComments();
                            }, 10000)
                        }
                        return returnBool
                    })
            },
            toComment(data) {
                return {
                    id: data.id,
                    userid: data.user_id,
                    user: data.commenter_name,
                    userimg: this.getImage(![3,6].includes(data.commenter_access_level) ? 1 : data.commenter_access_level),
                    datetime: moment(data.created_at).format('DD MMM [at] h:mmA'),
                    value: data.text
                }
            },
            async addComment() {
                if (!this.details.comment.value) return;

                this.details.comment.cancelToken.cancel();
                this.details.comment.recursive = false;
                this.details.comment.cancelToken = axios.CancelToken.source();

                this.details.comment.domCounter++;
                this.details.comment.focused = false;
                this.currentID = 0
                let data = JSON.parse(localStorage.getItem('lawfirm'))
                let user = `${(data.first_name || "").trim()} ${(data.last_name || "").trim()}`
                let access = this.access();

                let headers = { cancelToken: this.details.comment.cancelToken.token, headers: {...this.$session.get('lawfirm_headers').headers, Feature: 'counterfeit-checker'}};
                let payload = { client_listing_id : this.details.id, text: this.details.comment.value }
                await axios.post(`${process.env.VUE_APP_URL}/comments`, payload, headers)
                        .catch(ex => {
                            // this.$parent.showSnackbar('Something went wrong. Please try again..', false, 'invalid', 'fas fa-times-circle');
                            this.$parent.showSnackbar(ex.response.data.meta.message, false, 'invalid', 'fas fa-times-circle');

                        });

                this.updateCurrentComment({ id: `000${this.details.comment.domCounter}`, userid: data.id, userimg: this.getImage(![3, 6].includes(access) ? 1 : access), user: user, datetime: moment(Date.now()).format('DD MMM [at] h:mmA'), value: this.details.comment.value });
                this.details.comment.value = ""

                this.details.comment.recursive = true;
                this.getComments();
            },
            async removeComment(comment, index) {
                this.details.comment.cancelToken.cancel();
                this.details.comment.deleteIndex = -1;
                this.details.comment.recursive = false;
                this.updateCurrentComment(null, index);
                this.details.comment.cancelToken = axios.CancelToken.source();

                let headers = { cancelToken: this.details.comment.cancelToken.token, headers: {...this.$session.get('lawfirm_headers').headers, Feature: 'counterfeit-checker'}};
                await axios.delete(`${process.env.VUE_APP_URL}/comments/${comment.id}`, headers)
                    .then(response => {
                        this.$parent.showSnackbar('Comment removed successfully!', false, 'success', 'fas fa-check-circle');
                        this.currentID = 0
                    })
                    .catch(ex => {
                        // this.$parent.showSnackbar('Something went wrong. Please try again..', false, 'invalid', 'fas fa-times-circle');
                        this.$parent.showSnackbar(ex.response.data.meta.message, false, 'invalid', 'fas fa-times-circle');
                    });

                this.details.comment.recursive = true;
                this.getComments();
            },
            getResearcher() {
                let header = JSON.parse(JSON.parse(localStorage.getItem("lawfirm")).headers)
                header.headers.where = JSON.stringify({"access_level":[1,5]})
                this.$http.get(`users/multi?page_size=1000`,header)
                    .then(({ data })=>{
                        this.reassign.user.data = data.data.map(({ id, first_name, last_name, access_level }) => ({value: id, label: (first_name + " " + (last_name != null ? last_name : "")), access_level }))
                    }).catch(err=>{
                        this.$parent.showSnackbar("Something went wrong. Please contact system admin.")
                    })
            },
            getStatuses() {
                let header = JSON.parse(JSON.parse(localStorage.getItem("lawfirm")).headers)
                header.headers.where = JSON.stringify({"id":[1,2,3,4]})
                this.$http.get("qflag?page_size=100", header).then(
                    ({ data }) => {
                    this.reassign.qflag.data = data.data.map(({ id, label }) => ({ value: id, label: label == 'Qualified' ? 'For Review' : label == 'Researched' ? 'For Quality Checking' : label }))
                })
            },
            updateCurrentComment(comment, index = null) {
                if (index != null) {
                    this.details.comment.data.splice(index, 1);
                } else {
                    this.details.comment.data.push(comment);
                }
            },
            getImage(access) {
                return this.$parent.getImage('users', this.details.comment.accessRoles[access])
            },
            clearComment() {
                this.details.comment.value = "";
                this.details.comment.focused = false;
            },
            getCurrentStatus(status) {
                let state = "done"

                switch (status) {
                    case "Work In Progress":
                    case "Monitoring":
                        state = "ongoing"
                    break;
                    case "Rejected":
                    case "Invalid":
                        state = "invalid"
                    break;
                    case "Unchecked":
                        state = "unchecked"
                    break;
                    default:
                        state = "done"
                    break;
                }

                return { label: status, state }
            },
            detailsStatusID(status) {
                return this.statusOptions.find(stats => status.toLowerCase().trim() == stats.label.toLowerCase().trim()).value ?? ""
            },
            detailsStatusLabel(status) {
                return this.statusOptions.find(stats => status == stats.value).label ?? ""
            },
            async addListing(isImport = false) {                
                this.$parent.showSnackbar('Cross checking data in system.. Please wait..', true);
                let indexWasEmpty = false

                this.resetMatchActions()
                this.add.manual.checking = true
                this.add.import.checking = true
                this.add.adding = true
                this.add.import.isImport = isImport
                let headers = {headers: {...this.$session.get('lawfirm_headers').headers, Feature: 'counterfeit-checker'}};
                let counter = { success: 0, error: 0 };
                let payload = { client_listings: [] }
                let sourceData = isImport ? Object.values(this.add.import.sheets.data[this.add.import.sheets.selected]).filter(data => !Array.isArray(data)) : this.add.manual.data
                this.add.matches_found.data = []
                this.add.manual.duplicates = []

                console.log(sourceData)

                if (isImport) {
                    this.add.import.columns.storeUrl = this.add.import.columns.data.filter(data => data.associated == 1)[0]?.label
                    this.add.import.columns.productUrl = this.add.import.columns.data.filter(data => data.associated == 2)[0]?.label

                    if (this.add.import.start == "" || this.add.import.start == 0 || this.add.import.start == null) {
                        let message = this.add.import.start == "" || this.add.import.start == null
                            ? "No 'start from row' number specified"
                            : "Row index was set to 0"
                        this.$parent.showSnackbar(`${message}, setting to default row number (2)`, false, 'info', 'fas fa-info-circle');
                        this.add.import.start = 2;
                        indexWasEmpty = true;
                    }
                }

                sourceData.forEach(data => {
                    payload.client_listings.push({
                        campaign_id : this.$route.params.campaign_id,
                        employee_user_id : 0,
                        claim_type_id : isImport ? 0 : data.claimtype,
                        store_url : this.add.import.isImport ? data[this.add.import.columns.storeUrl]?.trim() : data.storeUrl?.trim() ?? "",
                        product_url : this.add.import.isImport ? data[this.add.import.columns.productUrl]?.trim() : data.productUrl?.trim() ?? ""
                    })
                });
                
                if (isImport) {
                    payload.client_listings = payload.client_listings.map(c => ({ ...c, store_url: c.store_url ?? '' }))
                    payload.start = this.add.import.start;
                    payload.withHeaders = this.add.import.withHeaders.length > 0
                }

                payload.data = payload.client_listings
                
                await axios.post(`${process.env.VUE_APP_URL}/client_listings/check`, payload, headers)
                    .then(({ data }) => {
                        this.add.response = data.data.map(d => {
                            if (d.success) counter.success++
                            else counter.error++

                            if (d.listing && d.listing.status === "Qualified") {
                                d.listing.status = "For Review";
                            }

                            if (d.status === "Qualified") {
                                d.status = "For Review";
                            }

                            return d
                        });
                        
                        this.$parent.showSnackbar(
                            payload.client_listings.length == counter.success && counter.success > 0
                                ? 'New data successfully added'
                                : payload.client_listings.length == counter.error || counter.success == 0 
                                    ? 'No new data added'
                                    : 'New data added but some were not' , false, (counter.error ? 'warning' : 'success'), 'fas fa-check-circle');

                        if (data.data.find(clientListing => clientListing.listing && ["For Review", "Monitoring", "Rejected"].includes(clientListing.listing.status))) {
                            this.$parent.showSnackbar('Existing entries with the same data and/or seller information has been found. Please move them to the appropriate statuses by clicking the icons under the "Action" column', false, 'info', 'fas fa-info-circle');
                        }

                        this.add.response.sort(function (a, b) {
                            if (a.listing && ["For Review", "Monitoring", "Rejected"].includes(a.listing.status)) {
                                return -1;
                            }
                            return 1;
                        });
                    })
                    .catch(err => {
                        if (err.response.data.meta.message) {
                            this.$parent.showSnackbar(err.response.data.meta.message, false, 'invalid', 'fas fa-times-circle');
                        }
                    })

                // if (counter.error == 0)
                //     $('.modal').modal('hide');
                
                this.add.manual.checking = false
                this.add.import.checking = false
                this.add.import.isImport = false
                this.add.adding = false
                this.getListings(!counter.error);
            },
            resetImportResponse() {
                this.add.response = []
                this.resetManual()
                this.resetUpload()
            },
            resetManual() {
                this.add.manual.data = [{ storeUrl: "", productUrl: "", claimtype: 0, valid: true }];
                this.add.manual.duplicates = []
                this.add.manual.invalid_url = [];
            },
            resetUpload() {
                this.add.import.file_data = {};
                this.add.import.file_name = "";
                this.add.import.error = "";
                this.add.import.duplicates = [];
                this.add.import.invalid_url = [];
                this.add.import.multiple_sheets_detected = false
                this.add.import.columns_selection = false
                this.add.import.uploading = false
                
                if (document.getElementById("import-listings-field") != null)
                    document.getElementById("import-listings-field").value = "";
            },
            toList(listing) {
                return { label: listing.seller_url || listing.listing_url, type: `info-${this.getCurrentStatus(listing.status).state}`, datetime: listing.created_at };
            },
            update() {
                let headers = {headers: {...this.$session.get('lawfirm_headers').headers, Feature: 'counterfeit-checker'}};
                let payload = {
                    status: this.detailsStatusLabel(this.details.status),
                    claim_type_id: this.details.claimtype == 0 ? null : this.details.claimtype,
                    listing_id: this.details.insystem.value
                }
                axios.patch(`${process.env.VUE_APP_URL}/client_listings/${this.details.id}`, payload, headers)
                    .then(async response => {
                        this.$parent.showSnackbar('Successfully updated data', false, 'success', 'fas fa-check-circle');
                        this.details.copy.status = this.details.status;
                        await this.getListings(false)
                        this.currentID = 0

                        if (this.details.status != 3)
                            $('.modal').modal('hide')
                        else {
                            let details = this.listings.filter(data => data.id == this.details.id)[0]
                            this.details.insystem.data.listing_status = details.listing_status;
                            this.details.insystem.data.seller_name = details.seller_name;
                            this.details.insystem.data.seller_url = details.seller_url;
                            this.details.insystem.data.listing_id = details.listing_id;
                            this.details.insystem.key = this.getRandomKey();
                        }
                    })
                    .catch(ex => {
                        console.log(ex);
                        this.$parent.showSnackbar(ex.response.data.meta.message, false, 'invalid', 'fas fa-times-circle');
                    });
            },
            getRandomKey() {
                return Math.random().toString(36).substr(2, 5);
            },
            initializeModalCloseListener() {
                let vm = this
                $(() => {
                    $('#listing-details-modal').on('hide.bs.modal', function (e) {
                        vm.details.comment.recursive = false;
                        vm.deleteListing.showConfirm = false
                        vm.deleteListing.status = false
                    });
                })
            },
            isValidURL(u){
                return /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/.test(u)
            },
            gotoPage(page) {
                var page_input = document.getElementById('pagination_input');

                if (page_input.value > this.last_page) {
                    page_input.value = this.last_page;
                }
                else if (page_input.value < 1) {
                    page_input.value = 1;
                }

                this.current_page = page_input.value;
                page_input.blur();
            },
            sort() {
                if (this.filters.order.selected === 0) {
                    this.listings.sort(function(a, b) {
                        let a1 = a.id;
                        let b1 = b.id;
                        if (a1 < b1) {
                            return -1;
                        }
                        else if (b1 < a1) {
                            return 1;
                        }
                        return 0;
                    });
                }
                else if (this.filters.order.selected === 1) {
                    this.listings.sort(function(a, b) {
                        let a1 = a.id;
                        let b1 = b.id;
                        if (a1 < b1) {
                            return 1;
                        }
                        else if (b1 < a1) {
                            return -1;
                        }

                        return 0;
                    });
                } else if (this.filters.order.selected === 2) {
                    this.listings.sort(function(a, b) {
                        return (a.listing_url || "").localeCompare(b.listing_url || "")
                    });
                } else if (this.filters.order.selected === 3) {
                    this.listings.sort(function(a, b) {
                        return (b.listing_url || "").localeCompare(a.listing_url || "")
                    });
                } else if (this.filters.order.selected === 4) {
                    this.listings.sort(function(a, b) {
                        return (a.status || "").localeCompare(b.status || "")
                    });
                } else {
                    this.listings.sort(function(a, b) {
                        return (b.status || "").localeCompare(a.status || "")
                    });
                }
            },
            resetDateFilter() {
                this.filters.daterange = { selected: { start: null, end: null }, start: moment().format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD'), max: moment().format('YYYY-MM-DD') };
            },
            generate() {
                this.add.import.generating = true;
                window.location.href = this.isLoggedIn ? "/static/xls/counterfeit-import-template.xlsx" : ''
                this.add.import.generating = false;
            },
            async importListings() {
                this.add.import.uploading = true
                this.add.import.multiple_sheets_detected = false
                this.$parent.showSnackbar('Verifying upload. Please wait..', true);

                let ext = this.add.import.file_data.name.split('.')
                ext = ext[ext.length - 1];

                if (!this.isFileTypeUploadable(ext)) {
                    this.add.import.error = 'Only allowed file types are .csv, .xls, .xlxs';
                    this.$parent.showSnackbar(this.add.import.error, false, 'invalid', 'fas fa-times-circle')
                    return
                }

                let headers = {
                    headers: {
                        ...this.$session.get('lawfirm_headers').headers,
                        Feature: 'counterfeit-checker',
                        'Content-Type': "multipart/form-data"
                    }
                };
                let counter = { success: 0, error: 0, sheets: 0 };
                let payload = new FormData();
                payload.append("starts", this.add.import.start);
                payload.append("campaign_id", this.$route.params.campaign_id);
                payload.append("file", this.add.import.file_data);
                payload.append("withHeaders", this.add.import.withHeaders.length > 0)
                payload.append("start", this.add.import.start)
                this.add.import.duplicates = []

                await axios.post(`${process.env.VUE_APP_URL}/client_listings/import`, payload, headers)
                    .then(response => {
                        if (Object.keys(response.data.meta.sheets)?.length > 0) {
                            this.add.import.multiple_sheets_detected = true
                            this.add.import.sheets.raw = response.data.meta.sheets
                            this.add.import.sheets.data = response.data.data
                            let sheets = []
                            Object.keys(response.data.meta.sheets).forEach(key => sheets.push({label: key, value: key}))
                            this.add.import.sheets.options = sheets
                            this.add.import.sheets.selected = this.add.import.sheets.options[0].value
                        }

                        let message = ""
                        if (this.add.import.start == "" || this.add.import.start == 0 || this.add.import.start == null) {
                            message = this.add.import.start == "" || this.add.import.start == null
                                ? "No 'start from row' number specified"
                                : "Row index was set to 0"
                            // this.$parent.showSnackbar(`${message}, setting to default row number (2)`, false, 'info', 'fas fa-info-circle');
                            this.add.import.start = 2;
                        }

                        this.add.import.uploading = false
                        this.$parent.showSnackbar(`Successfully imported file${ message ? '; ' + message + ', setting to default row number (2)' : '!'}`, false, message ? 'info' : 'success', 'fas fa-check-circle');
                    })
                    .catch(error => {
                        this.$parent.showSnackbar(error.response.data.meta.message, false, 'invalid', 'fas fa-times-circle')
                        this.resetUpload()
                    })

                this.add.import.error = ''
            },
            isFileTypeUploadable(ext) {
                if (["xls" , "xlsx" , "xlsm" , "csv"].includes(ext))
                    return true

                return false
            },
            getStatusOptions(current) {
                let stuffs = { data: this.filters.status.data, status: this.details.copy.status }
                return stuffs.status == current // check if copy and current status is the same
                    ? stuffs.data.filter(data => current > 1 ? data.value != 1 : data.value < 3) // if true, check if current is not status "Unchecked" or greater than one
                    : stuffs.data.filter(data => stuffs.status > 1 ? data.value != 1 : data.value < 3)
            },
            checkDateFilter(position) {
                if (position == 'start') {
                    if (this.filters.daterange.start > this.filters.daterange.end && this.filters.daterange.end != null)
                        this.filters.daterange.start = this.filters.daterange.end
                }

                if (position == 'end') {
                    if (this.filters.daterange.end < this.filters.daterange.start && this.filters.daterange.start != null)
                        this.filters.daterange.end = this.filters.daterange.start
                }
            },
            cleanPasteInput() {
                setTimeout(() => {
                    let value = this.details.insystem.value.toString().replace(/\D/g,'');
                    this.details.insystem.value = value;
                    $('#existing-lid').val(value)
                });
            },
            getHistory() {
                let headers = { cancelToken: this.details.comment.cancelToken.token, headers: {...this.$session.get('lawfirm_headers').headers, Feature: 'counterfeit-checker'}};
                this.generalHistory = []
                
                axios.get(`${process.env.VUE_APP_URL}/client_listings/${this.details.id}/history`, headers)
                    .then(res => {
                        console.log(res)
                        this.generalHistory = res.data.data

                        this.currentID = this.details.id
                    })
                    
            },
            resetFilters() {
                this.filters.search.value = ""
                this.filters.status.selected = 0
                this.filters.order.selected = 1
                this.filters.claimtype.selected = 0
                this.filters.daterange.selected.start = null
                this.filters.daterange.selected.end = null
            },
            checkMatchAction(value) {
                this.add.matches_found.is_bulk_action = true

                if (this.add.matches_found.bulk_action.length > 0 || this.add.matches_found.bulk_action.includes(value))
                    this.add.matches_found.bulk_action = []

                if ($("#match-option-skip-all").is(":checked") || $("#match-option-use-product").is(":checked"))
                    this.add.matches_found.bulk_action.push(value)
            },
            resetMatchActions() {
                this.add.matches_found.bulk_action = []
            },
            individualSetAction() {
                this.resetMatchActions()
                this.add.matches_found.is_bulk_action = false
            },
            setActions(value) {
                this.add.matches_found.data.forEach(data => {
                    data.action = value
                })
            },
            async addListingMatch() {
                this.add.adding = true
                let headers = {headers: {...this.$session.get('lawfirm_headers').headers, Feature: 'counterfeit-checker'}};
                let counter = { success: 0, error: 0 };
                let payload = { client_listings: [] }
                let sourceData = this.add.import.isImport ? Object.values(this.add.import.sheets.data[this.add.import.sheets.selected]).filter(data => !Array.isArray(data)) : this.add.manual.data

                sourceData.forEach(data => {
                    payload.client_listings.push({
                        campaign_id : this.$route.params.campaign_id,
                        employee_user_id : 0,
                        claim_type_id : this.add.import.isImport ? 0 : data.claimtype,
                        store_url : this.add.import.isImport ? data[this.add.import.columns.storeUrl]?.trim() : data.storeUrl?.trim(),
                        product_url : this.add.import.isImport ? data[this.add.import.columns.productUrl]?.trim() : data.productUrl?.trim()
                    })
                });

                for (let index = 0; index < this.add.matches_found.data.length; index++) {
                    let data = this.add.matches_found.data[index];
                    let details = data.listing_details[data.selected];
                    let dataIndex = payload.client_listings.findIndex(item => item.store_url == data.store_url && item.product_url == details["product url"])
                    payload.client_listings[dataIndex].status = data.action == 1 ? "In the System" : "Unchecked"
                    payload.client_listings[dataIndex].listing_id = details.listing_id
                    payload.client_listings[dataIndex].seller_id = details.seller_id
                }

                if (payload.client_listings.length == 0) {
                    $('#matches-found-modal').modal('hide');
                    this.$parent.showSnackbar("No new data was added", false, 'info', 'fas fa-info-circle')
                    this.add.adding = false
                    return;
                } 

                await axios.post(`${process.env.VUE_APP_URL}/client_listings/multiple/insert`, payload, headers)
                    .then(response => {
                        response.data.forEach(data => {
                            if (data?.original?.meta.status == 'error') {
                                counter.error++;
                                if (data?.original?.meta.error_type == 'duplicate') {
                                    if (this.add.import.isImport)
                                        this.add.import.duplicates.push(data?.original?.meta.url)
                                    else
                                        this.add.manual.duplicates.push(data?.original?.meta.url)
                                }

                                if (data?.original?.meta.error_type == 'invalid_url') {
                                    if (this.add.import.isImport)
                                        this.add.import.invalid_url.push(data?.original?.meta.url)
                                    else
                                        this.add.manual.invalid_url.push(data?.original?.meta.url)
                                }
                            }
                        });

                        if (counter.error > 0) {
                            if (response.data.length == this.add.manual.duplicates.length || response.data.length == this.add.import.duplicates.length)
                                this.$parent.showSnackbar("Duplicates were found when trying to add", false, 'invalid', 'fas fa-times-circle');
                            else if (response.data.length == this.add.manual.invalid_url.length || response.data.length == this.add.import.invalid_url.length)
                                this.$parent.showSnackbar("Invalid URLs were found when trying to add", false, 'invalid', 'fas fa-times-circle');
                            else {
                                let error_message = `Successfully inserted data but found `;
                                 error_message += this.add.manual.duplicates.length || this.add.import.duplicates.length ? this.add.manual.duplicates.length || this.add.import.duplicates.length + ' duplicate(s)' : ''
                                 error_message += (this.add.manual.duplicates.length || this.add.import.duplicates.length) && (this.add.manual.invalid_url.length || this.add.import.invalid_url.length) ? ' and ' : ''
                                 error_message += this.add.manual.invalid_url.length || this.add.import.invalid_url.length ? this.add.manual.invalid_url.length || this.add.import.invalid_url.length + ' invalid url(s)' : ''

                                this.$parent.showSnackbar(error_message, false, 'warning', 'fas fa-exclamation-triangle');
                            }
                            
                            $('#matches-found-modal').modal('hide');
                            setTimeout(_ => $('#import-listing-modal').modal({ backdrop: 'static', keyboard: false }), 500);
                            
                            if (this.add.import.isImport)
                                this.add.import.columns_selection = false
                        }
                        else
                            this.$parent.showSnackbar('New data successfully added', false, 'success', 'fas fa-check-circle');
                    });

                if (counter.error == 0)
                    $('.modal').modal('hide');
                
                this.add.import.isImport = false
                this.add.adding = false
                this.getListings(!counter.error);
            },
            getImportColumn() {
                this.add.import.columns.data = Object.values(this.add.import.sheets.raw[this.add.import.sheets.selected]).map(data => {
                    let associated = 0

                    if (data != null && data != "") {
                        if (["store url", "seller url"].includes(data?.toLowerCase()))
                            associated = 1
    
                        if (["listing url", "product url"].includes(data?.toLowerCase()))
                            associated = 2
                    }

                    return { label: data, associated: associated }
                })
                this.add.import.columns_selection = true
            },
            backFromColumnSelect() {
                this.add.import.columns_selection = false
            },
            checkAssociatedColumns(index) {
                let value = this.add.import.columns.data[index].associated
                
                for (let i = 0; i < this.add.import.columns.data.length; i++) {
                    if (value == 1 && index != i && this.add.import.columns.data[i].associated == 1) {
                        this.add.import.columns.data[i].associated = 0
                    }

                    if (value == 2 && index != i && this.add.import.columns.data[i].associated == 2) {
                        this.add.import.columns.data[i].associated = 0
                    }
                }
            },
            getAltStatus(status) {
                return status == "Recheck" 
                    ? "For Rework" 
                    : status == "Qualified"
                        ? "For Review"
                        : status == "Hacked"
                            ? "Auto Qualified"
                            : status
            },
            copytoClipboard(str = "", prop_name = 'product url') {
                if (str != "") {
                    let modal = []
                    let container = (modal = document.querySelectorAll('.modal.in, .modal.show')).length ? modal : document
                    const el = document.createElement('textarea')
                    el.value = str
                    el.setAttribute('readonly', '')
                    el.style.position = 'absolute'
                    el.style.left = '-9999px'
                    container[modal.length ? 0 : "body"].appendChild(el)
                    el.select()
                    document.execCommand('copy')
                    container[modal.length ? 0 : "body"].removeChild(el)

                    // call the snackbar when copied
                    this.$parent.showSnackbar(`You copied ${prop_name}: ${str}`, false, 'success', 'fas fa-check')
                }
            },
            getExtension(filename) {
                let split = filename.split('.')
                return '.' + split.pop();
            },
            getFileName(filename) {
                let split = filename.split('.')
                split.splice(split.length, 0)
                return split.join('')
            },
            removeListing() {
                this.deleteListing.status = true
                
                let headers = { cancelToken: this.details.comment.cancelToken.token, headers: {...this.$session.get('lawfirm_headers').headers, Feature: 'counterfeit-checker'}};
                
                this.$http.delete(`client_listings/${this.details.id}`, headers)
                    .then(res => {
                        this.closeModal()
                        this.listings = this.listings.filter(l => l.id != this.details.id)
                        this.$parent.showSnackbar(`Successfully deleted listing`, false, 'success', 'fas fa-check')
                    })
                    .catch(err => {
                        this.$parent.showSnackbar("Something went wrong when deleting listings", false, 'invalid', 'fas fa-times-circle');
                    })
                    .finally(() => {
                        this.deleteListing.status = false
                    })
            },
            async getPredefinedReasons() {
                const headers = this.$session.get('lawfirm_headers')
                this.$store.dispatch('resetPredefinedReasonState')
                let qflagId = this.qflagId

                await axios.get(`${process.env.VUE_APP_URL}/predefined_reasons/${qflagId}?page_size=100`, headers)
                    .then(pre => {
                        this.predefinedReasons = pre.data.data
                    })
            },
            async changeDataCheckerStatus() {
                let qflag_id = this.qflagId
                let headers = this.$session.get('lawfirm_headers');
                let payload = {
                    listing_ids : this.selectedImportListingId,
                    reasons: this.$store.state.checked_reasons,
                }
                await axios.patch(`${process.env.VUE_APP_URL}/listings/multiple/statuschange/${qflag_id}`, payload, headers)
                    .then(response => {
                        if(response.data.meta.code == 200) {
                            this.add.response = this.add.response.filter(clientListing => this.selectedImportListingId != this.getListingId(clientListing));
                            this.$parent.showSnackbar("Successfully changed the status", false, "success", "fas fa-check-circle")
                            this.monitoringClicked = false
                            this.acceptedClicked = false
                            this.rejectedClicked = false
                            if (this.add.response.length) {
                                this.hideEditStatusModal();
                                this.showListingSummary();
                            }
                            else {
                                this.closeModal();
                                this.getListings();
                            }

                            this.setRelatedCampaignDuplicateBypass(false)
                        }
                    }).catch(async err => {
                        let error = {err}
                        const duplicate_logs = error?.err?.response?.data?.duplicate_logs
                        if (duplicate_logs) {
                            $('.modal').modal('hide')

                            await sleep(500)

                            this.setRelatedCampaignDuplicateContinueFunction(() => this.changeDataCheckerStatus())
                            this.setRelatedCampaignDuplicates(duplicate_logs)
                            this.$parent.showSnackbar(`${error.err.response.data.message}`,false,'invalid','fas fa-times-circle')
                            return
                        }
                        this.$parent.showSnackbar(`${error.err.response.data.meta.message}`,false,'invalid','fas fa-times-circle')
                    })
            },

            setInvalidNotes() {
                if(this.currentStatus?.label == 'Invalid') {
                    this.details.commentTab = false
                    this.details.invalidNote = true
                    this.details.invalidHistory = false
                }

                if(this.currentStatus?.label !== 'Invalid') {
                    this.details.commentTab = true
                    this.details.invalidNote = false
                    this.details.invalidHistory = false
                }
            },
            statusHistory(_computedInvalidNote) {

                let text = `Updated Status to <b>Invalid</b> with the following reason(s): <br/> 
                            <span style="display: flex; flex-direction: column; row-gap: 5px; margin-left: 20px;">
                                <span style="font-weight: bold">
                                    - ${_computedInvalidNote.main ?? ''}
                                </span>
                                <span style="margin-left: 30px">
                                    <i>
                                        ${_computedInvalidNote.other ?? ''}
                                    </i>
                                </span>
                                <span style="margin-left: 30px">
                                    <i>
                                        ${_computedInvalidNote.sub ?? ''}
                                    </i>
                                </span>
                            </span>`
                return text
            },
            checkAllListing(){
                this.select_all_text = this.is_select_all ? "Unselect" : "Select"
                this.selected_listings = []
                var inputs = document.querySelectorAll("input[name='client_listings_data_checker']");
                for(let i = 0; i < inputs.length; i++) {
                    this.selected_listings.push(parseInt(inputs[i].value)) 
                    inputs[i].checked = this.is_select_all;
                    if (this.is_select_all)
                        inputs[i].parentNode.parentNode.parentNode.parentNode.classList.add('checked')
                    else
                        inputs[i].parentNode.parentNode.parentNode.parentNode.classList.remove('checked')
                }
                if(this.is_select_all === false){
                    this.selected_listings = []
                }
                this.is_select_all = !this.is_select_all
            },
            openModal(modalId) {
                $(`#${modalId}`).modal('show')
            },
            async downloadDataChecker() {
                let headers = this.$session.get('lawfirm_headers')
                let payload = {
                    id: this.selected_listings
                }

                window.open(`${process.env.VUE_APP_URL}/api/v2/client_listings/export?token=${this.token_noBearer}&${qs.stringify(payload, { encode: false })}`, '_blank')

                // await axios.post(`${process.env.VUE_APP_URL}/api/v2/client_listings/export?token=${this.token_noBearer}`,payload,headers).then(
                //     response => {
                //         console.log(response)
                //     }
                // ).catch(error => {
                //     console.log(error)
                // })
            },
        },
        watch: {
            current_page() {
                this.selected_listings = []
                this.is_select_all = false
                this.checkAllListing()
            },
            page_size() {
                this.selected_listings = []
                this.is_select_all = false
                this.checkAllListing()
            },
            selectedFilter() {
                this.selected_listings = []
                this.is_select_all = false
                this.checkAllListing()
            },
            selectedDaterange() {
                this.selected_listings = []
                this.is_select_all = false
                this.checkAllListing()
            },
            '$route.name': {
                handler() {
                    this.initializeSelectedCampaign();
                    this.initializeModalCloseListener();
                }
            },
            '$route.query.id': {
                handler(value) {
                    if (value) {
                        this.getListings();
                    }
                }
            },
            'add.current': {
                handler(value) {
                    if (value) {
                        this.resetManual();
                        this.resetUpload();
                    }
                }
            },
            'filters.order.selected'() {
                this.sort();
            },
            filtered_listings(new_val) {
                this.visible = new_val.visible;
                this.visible_total = new_val.visible_total;
            },
            'details.status'() {
                this.details.insystem.value = this.details.insystem.data.listing_id || ''
            },
            'details.commentTab': {
                handler(bool) {
                    this.reassign.status = false;

                    if(!bool && this.details.id != this.currentID) {
                        this.getHistory()

                    }
                }
            },
            'add.matches_found.bulk_action': {
                handler (value) {
                    if (value.length == 1 && this.add.matches_found.is_bulk_action) {
                        if (value[0] == 1)
                            this.setActions(1)
                        else if (value[0] == 2)
                            this.setActions(2)

                        return;
                    } else if (this.add.matches_found.is_bulk_action) {
                        this.setActions(0)
                    }                    
                }
            },
            'details.invalidHistory': {
                handler (value) {
                    if(!value) {
                        this.details.invalidNote = true
                        this.details.commentTab = false
                    }
                    else {
                        this.details.invalidNote = false
                        this.details.commentTab = false
                    }
                }
            }
        }
    }
</script>

<style>
    
</style>

<style scoped>
    .main-con {
        padding: 200px 50px 100px 87px;
    }

    .main-con table tbody tr td.time-col {
        width: 160px;
        text-align:center;
    }

    .main-con table tbody tr {
        cursor: pointer;
    }

    .main-con table tbody tr:active {
        background: #ddd;
    }

    .main-con table tbody tr td.notification-details {
        letter-spacing: 0.4px;
    }

    .main-con table tbody tr td.notification-details img {
        height: 20px;
        width: 20px;
    }

    .d-inline >>> .text-break {
        display:inline !important;
    }

    .d-inline >>> br:after {
        content: ' ';
    }
    .d-inline >>> br {
        content: ' ';
    }

    .unread {
        background: #ececec;
    }

    tbody tr {
        border-bottom: 1px solid #dcdada;
    }

    #import-listing-modal h5 {
        font-size: 16px;
        color: #515365;
    }

    #import-listing-modal h5 span {
        font-size: 12px;
        line-height: 16px;
        margin-top: 3px;
    }

    #import-listing-modal .left-con,
    #import-listing-modal .right-con {
        max-width: 400px;
        overflow-y: auto;
    }

    #listing-details-modal h5 {
        font-size: 16px;
        color: #515365;
        /* width: 80%; */
    }

    #listing-details-modal h5 span {
        font-size: 12px;
        line-height: 16px;
        margin-top: 3px;
    }

    #listing-details-modal .left-outer-con {
        background: #f0f4ff;
        border-radius: 8px;
        margin-top: 6px;
        padding: 20px 18px;
        min-height: 470px;
    }

    #listing-details-modal .counter-image {
        height: 42px;
        width: 42px;
        position: relative;
        top: -5px;
    }

    #listing-details-modal .left-con,
    #listing-details-modal .right-con {
        max-width: 400px;
        font-size: 12px;
        height: 318px;
        overflow-y: auto;
        overflow-x: hidden;
        color: #515365;
    }

    #listing-details-modal .left-con img {
        height: 12px;
        width: 12px;
        margin-right: 10px;
    }

    #listing-details-modal .comments-con {
        -webkit-transition: height 0.5s ease;
        transition: height 0.5s ease;
        overflow-y: auto;
        height: 346px;
    }

    #listing-details-modal .comments-con.lessen {
        height: 281px;
    }

    .info-status {
        display: inline-block;
        min-width: 98px;
        min-height: 25px;
        max-height: fit-content;
        border-radius: 25px;
        font-size: 12px;
        line-height: 24px;
        color: #ffffff;
        text-align: center;
        position: relative;
        top: -5px;
        padding: 0 11px;
    }

    .info-status.pending {
        background: #8b9dc3;
    }

    .info-status.ongoing {
        background: #f3a17a;
    }

    .info-status.invalid {
        background: #ec7174;
    }

    .info-status.done {
        background: #29ABA2;
    }
    .info-status.unchecked{
        background: #b4b1b1;
    }


    .comment-con {
        -webkit-transition: background .5s ease;
        transition: background .5s ease;
        padding: 17px;
        padding-left: 58px;
        border-radius: 8px;
    }

    .comment-con:hover {
        background: #f7f8fb;
    }

    .comment-con .delete-comment-btn {
        opacity: 0;
        float: right;
    }

    .comment-con .delete-comment-btn img {
        height: 12px;
        width: 12px;
    }

    .comment-con:hover .delete-comment-btn {
        opacity: 1;
    }

    .comment-con img.icon {
        position:absolute;
        height: 40px;
        width: 40px;
        left: 10px;
        top: 17px;
        border-radius: 100%;
    }

    .comment-details-con h5 {
        color: #515365;
        font-size: 16px;
        margin-bottom: 2px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 85%;
        line-height: 100%;
    }

    .comment-details-con .text-muted {
        color: #999a9b !important;
        font-size: 11px !important;
    }

    .comment-details-con-con {
        letter-spacing: 0.3px;
        font-size: 13px;
    }

    .comments-con .active-delete .comment-con:hover {
        background: #fae3e4;
    }

    .comments-con .active-delete {
        background: #fae3e4;
        border-radius: 8px;
    }

    .comments-con .active-delete .delete-comment-btn {
        opacity: 1;
    }

    .remove-confirm-text {
        margin-bottom: 0;
        font-size: 10px;
    }
    
    .remove-confirm-btn {
        -webkit-transition: opacity .5s ease;
        transition: opacity .5s ease;
        display: inline-block;
        width: 24px;
        cursor: pointer;
    }

    .remove-confirm-btn img {
        width: 100%;
    }
    
    .remove-confirm-btn:hover {
        opacity: 0.5;
    }

    .remove-confirm-con,
    .remove-confirm-con .a-modal-seller-info-item {
        background: #eaeef5;
    }

    .col-form-label {
        color: #383A3D;
        margin-left: 5px;
        font-weight: 500 !important;
        font-size: 13px;
    }

    .from-label {
        padding-top:0px;
    }

    .form-control {
        font-size: 14px;
    }

    .apply-button {
        margin-top:10px;
    }

    .table-hover tr:hover {
        background: #d8d8d8 !important;
    }

    .input-icon {
        position: absolute;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        top: 50%;
        right: 18px;
    }

    .titlebar-con {
        padding-left: 46px;
    }

    .has-bullet-separator {
        position: relative;
    }

    .has-bullet-separator::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        z-index: 2;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        height: 5px;
        width: 5px;
        border-radius: 100%;
    }

    .add-img-con {
        margin-top: 96px;
    }
    
    .pagination-footer {
        background: #fff;
        position: fixed;
        padding: 10px 50px 0 80px;
        height: 60px;
        width: 100%;
        bottom: 0;
        left: 0;
    }

    .empty-listing-con {
        position:relative;
        height: 28vh;
    }

    .empty-listing-con img {
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        position: absolute;
        width: 100%;
        max-width: 376px;
        left: 50%;
        top: 105%;
    }

    .counterfeit-checker-history-con {
        overflow: auto;
        min-height: 398px;
        max-height: 380px;
        padding: 10px;
        padding-right: 29px;
    }

    .empty-history-state {
        width: 76%;
        text-align:center;
        padding-top: 30px;
        margin: 0 24px 0 auto;
    }

    .empty-history-state img {
        width: 100%;
    }

    #matches-found-modal h5 {
        font-size: 24px;
        font-weight: 500;
        color: #DC1A1A;
    }

    #matches-found-modal h5 span {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.24px;
        color: #53606DB3;
        display: block;
        margin-top: 5px;
    }

    #matches-found-modal table tr,
    #import-sheet table tr {
        font-size: 12px;
    }

    #matches-found-modal table thead tr,
    #import-sheet table thead tr {
        background: #F5F6FA;
        border-bottom: 2px solid rgb(214, 214, 216);
        color: #202229;
    }

    #matches-found-modal table tbody tr {
        border-bottom: 2px solid #F5F6FA;
        background: #DC1A1A52;
    }

    #matches-found-modal table tbody tr:last-child {
        border-bottom: none;
    }

    #matches-found-modal table tbody .match-found-listing {
        -webkit-transition: .5s ease;
        transition: .5s ease;
    }

    #matches-found-modal table tbody .match-found-listing:hover {
        background: #f9c1c1;
    }

    #matches-found-modal table tbody .match-found-listing.active {
        background: #f99c9c;
    }

    #import-sheet table tbody tr {
        border-bottom: none;
    }

    .table-scroll {
        width:100%;
        display: block;
        empty-cells: show;
    }

    .table-scroll thead,
    .table-scroll tfoot{
        position:relative;
        display: block;
        width:100%;
    }

    .table-scroll tbody{
        display: block;
        position:relative;
        width:100%;
        height: 280px;
        overflow-y:auto;
    }

    .table-scroll tr{
        width: 100%;
        display: inline-block;
    }

    .table-scroll td,.table-scroll th{
        display: inline-block;
        word-break: break-all;
        padding: 1rem;
        text-align:left;
    }

    #import-listing-modal .table-scroll tr td .status {
        display: inline-block;
        border-radius: 4px;
        padding: 3px;
        text-align: center;
        color: #fff;
        text-transform: uppercase;
        font-size: 9px;
        font-weight: 500;
        background: #29ABA2;
        min-width: 66px;
    }

    #import-listing-modal .table-scroll tr td .status.not_found {
        background: #DC3C40;
    }

    #import-listing-modal .table-scroll tr td .status.wip {
        background: #f3a17a;
    }

    #import-listing-modal .table-scroll tr td .status.ongoing {
        background: #29ABA2;
    }

    #import-listing-modal .table-scroll tr td .status.unchecked {
        background: #b4b1b1;
    }

    #import-listing-modal .table-scroll tr td .status.others {
        background: #3c97dc;
    }

    #import-listing-modal .table-scroll th {
        display: inline-block;
        color: #627aad;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 1.4px;
    }

    #import-sheet .table-scroll tbody {
        height: 232px;
    }

    #listing-details-modal .remove-confirm {
        right: 0;
        bottom: 120%;
        background: white;
        width: 267px;
        box-shadow: 0px 1px 6px #00000029;
        border: 1px solid #7070703D;
        z-index: 2;
    }

    .listing-preview-counter-image {
        position: relative;
        height: 68px;
        width: 112px;
        top: -5px;
        overflow: hidden;
    }

    .listing-preview-counter-image img {
        height: 100%;
        width: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%) scale(1.5);
        transform: translate(-50%, -50%) scale(1.5)
    }

    /*
    * START EDIT LISTING STYLE
    */
    .edit-listing-button{
        display: flex;
        justify-content: flex-start;
        padding-top: 30px;
    }
    .edit-listing-container{
        display:flex;
        justify-content: space-between;
        flex-direction: column;
        margin-top: -30px;
        text-align: left;
        height: 170px;
    }
    .listing-label{
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #383A3D26;
        border-radius: 4px;
        width: 113px;
        height: 25px;
        display:flex;
        justify-content: flex-start;
        align-items: center;
    }
    .listing-url{
        text-decoration: underline;
        font: normal normal bold 12px/16px Roboto;
        letter-spacing: 0px;
        color: #333333DE;
    }
    .listing-url:hover{
        color: #3E88DA;
    }
    .listing-url-container{
        word-wrap: break-word;
        font: normal normal bold 12px/16px Roboto;
    }
    .import-listing-details{
        width: 674px;
        background-color: rgba(138, 176, 219, .1);
        border: 1px solid rgba(138, 176, 219, .4);
        border-radius: 8px;
        padding: 10px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        row-gap: 5px;
    }
    .reasons-description{
        width: 674px;
        padding: 10px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        row-gap: 5px;
    }
    .import-listing-description{
        font: normal normal normal 14px/24px Roboto;
        letter-spacing: 0px;
        color: #515365;
    }
    .listing-details-message{
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #DC3C405C;
        border-radius: 4px;
        font: normal normal bold 10px/21px Roboto;
        letter-spacing: -0.2px;
        color: #595959;
        text-transform: uppercase;
        padding: 2px;
        height: 20px;
    }
    .status-change-selection{
        width: 470px;
        height: 430px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #6EB4F9;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        padding: 20px;
        align-items: center;
    }
    .status-change-buttons{
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }
    .button-monitoring{
        width: 300px;
        height: 80px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #F3A17A42;
        border-radius: 4px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 15px;
        column-gap: 15px;
        outline: none;
    }
    .monitoring-button-icon{
        width: 40px;
        height: 16px;
        background-image: url("/static/svg/Mark_Monitoring.svg");
        background-repeat: no-repeat;
    }
    .button-monitoring:hover{
        background: #F3A17A45 0% 0% no-repeat padding-box;
        border-radius: 4px;
    }
    .button-monitoring:hover .monitoring-button-icon{
        background-image: url("/static/svg/Orange.svg");
    }
    .button-monitoring:hover .monitoring-detail-header{
        color:#FF5722;
    }
    .button-monitoring.monitoringClicked {
        background: #F3A17A45 0% 0% no-repeat padding-box;
    }
    .button-monitoring.monitoringClicked .monitoring-button-icon {
        background-image: url("/static/svg/Orange.svg");
    }
    .button-monitoring.monitoringClicked .monitoring-detail-header {
        color:#FF5722;
    }
    .status-button-details{
        display: flex;
        flex-direction: column;
        text-align: left;
    }
    .monitoring-detail-header{
        font: normal normal bold 14px/19px Roboto;
        letter-spacing: 0.24px;
        color: #141517;
    }
    .monitoring-detail-description{
        font: normal normal normal 12px/16px Roboto;
        letter-spacing: 0px;
        color: #515365;
        background: none;
    }
    
    .button-accept{
        width: 300px;
        height: 80px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #29ABA240;
        border-radius: 4px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 15px;
        column-gap: 15px;
        outline: none;
    }
    .accept-button-icon{
        width: 53px;
        height: 20px;
        background-image: url("/static/svg/Mark_Accepted.svg");
        background-repeat: no-repeat;
    }
    .button-accept:hover{
        background: #DFF6F4 0% 0% no-repeat padding-box;
        border-radius: 4px;
    }
    .button-accept:hover .accept-button-icon{
        background-image: url("/static/svg/Green.svg")
    }
    .button-accept:hover .accept-detail-header{
        color:#29ABA2;
    }
    .button-accept.acceptedClicked {
        background: #DFF6F4 0% 0% no-repeat padding-box;
    }
    .button-accept.acceptedClicked .accept-button-icon{
        background-image: url("/static/svg/Green.svg")
    }
    .button-accept.acceptedClicked .accept-detail-header{
        color:#29ABA2;
    }
    .status-button-details{
        display: flex;
        flex-direction: column;
        text-align: left;
    }
    .accept-detail-header{
        font: normal normal bold 14px/19px Roboto;
        letter-spacing: 0.24px;
        color: #141517;
    }
    .accept-detail-description{
        font: normal normal normal 12px/16px Roboto;
        letter-spacing: 0px;
        color: #515365;
        background: none;
    }

    .button-reject{
        width: 300px;
        height: 80px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #F5686C6B;
        border-radius: 4px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 15px;
        column-gap: 15px;
        outline: none;
    }
    .reject-button-icon{
        width: 40px;
        height: 30px;
        background-image: url("/static/svg/Icon_Rejected.svg");
        background-repeat: no-repeat;
    }
    .button-reject:hover{
        background: #F5686C38 0% 0% no-repeat padding-box;
        border-radius: 4px;
    }
    .button-reject:hover .reject-button-icon{
        background-image: url("/static/svg/Red.svg")
    }
    .button-reject:hover .reject-detail-header{
        color:#DC3C40;
    }
    .button-reject.rejectedClicked {
        background: #F5686C38 0% 0% no-repeat padding-box;
    }
    .button-reject.rejectedClicked .reject-button-icon{
        background-image: url("/static/svg/Red.svg")
    }
    .button-reject.rejectedClicked .reject-detail-header{
        color:#DC3C40;
    }
    .status-button-details{
        display: flex;
        flex-direction: column;
        text-align: left;
    }
    .reject-detail-header{
        font: normal normal bold 14px/19px Roboto;
        letter-spacing: 0.24px;
        color: #141517;
    }
    .reject-detail-description{
        font: normal normal normal 12px/16px Roboto;
        letter-spacing: 0px;
        color: #515365;
        background: none;
    }
    .status-change-confirm-btn{
        padding-top: 20px;
    }
    .change-status-btn{
        width: 250px;
    }
    .change-status-btn:hover{
        opacity: 0.56;
    }
    .selectedImportListing{
        display: inline-block;
        border-radius: 4px;
        padding: 3px;
        text-align: center;
        color: #fff;
        text-transform: uppercase;
        font-size: 9px;
        font-weight: 500;
        min-width: 66px;
    }
    .selectedImportListing.forReview{
        background: #29ABA2;
    }
    .selectedImportListing.rejected{
        background: #ec7174;
    }
    .selectedImportListing.monitoring{
        background: #f3a17a;
    }
    .import-status{
        background: #29ABA2;
        display: inline-block;
        border-radius: 4px;
        padding: 3px;
        text-align: center;
        color: #fff;
        text-transform: uppercase;
        font-size: 9px;
        font-weight: 500;;
        min-width: 66px;
    }
    .import-status.not_found{
        background: #DC3C40;
    }
    .details-labels{
        margin-bottom: 10px;
    }
    /*
    *END EDIT LISTING STYLE
    */

    
    /*
    * CM VARIATIONS
    */
    .gui-cm .has-bullet-separator::before {
        background: #2D599E;
    }
    /*
    * END CM VARIATIONS
    */

    /* INVALID NOTES START */

    .invalid-note-btn {
        color: #EC7174;
        letter-spacing: 1.25px;
        font: normal normal bold 14px/19px Roboto;
    }
    .invalid-note-btn:hover {
        background-color: #f1c8c9 !important;
        color: #EC7174 !important;
    }

    .invalid-note-con {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }
    .invalid-note-header {
        display: flex;
        align-items: flex-end;
        column-gap: 10px
    }
    .invalid-note-label {
        font: normal normal bold 12px/16px Roboto;
        color: #DC3C40;
        letter-spacing: 0px;
        text-transform: uppercase;
    }
    .invalid-note-team {
        font: normal normal bold 20px/26px Roboto;
        letter-spacing: 0px;
        color: #515365;
    }

    .invalid-note-body {
        display: flex;
        flex-direction: column;
        height: 350px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px dashed #EC7174;
        border-radius: 4px;
        padding: 20px;
    }
    .invalid-note-important-note {
        display: flex;
        column-gap: 10px;
        color: #EC7174;
    }
    .invalid-note-label {
        font: normal normal small 14px/18px Roboto;
        letter-spacing: 0px;
        color: #EC7174;
    }
    .invalid-note-reasons {
        padding: 40px;
        height: 300px;
        overflow-y: auto;
    }
    .reasons-note {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        margin-bottom: 10px;
    }
    .reason-main-note {
        font: normal normal bold 14px/21px Roboto;
        letter-spacing: 0px;
        color: #383A3D;
    }
    .reason-sub-other {
        margin-left: 30px;
        font: normal normal normal 14px/21px Roboto;
        letter-spacing: 0px;
        color: #383A3D;
    }

    /* INVALID NOTES END */
</style>
